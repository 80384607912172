// Terms.js
import React from 'react';

const Terms = () => {
  return (
    <div style={{ padding: '20px', lineHeight: '1.6', marginTop:'4rem' }}>
      <h1>Terms of Service</h1>
      <p>
        The website “ITAccurateTraining.com” is the property of IT Accurate Private Limited, and it intends to provide
        educational information that may be of interest to users/visitors. The contents of the site, including texts,
        images, and videos, are the copyrighted material of IT Accurate Private Limited unless mentioned otherwise. All
        rights are reserved. The trademarks referred to are the property of their respective owners. The user shall be
        deemed to have agreed to and accepted the following terms and conditions upon the user’s use of the website
        “itaccuratetraining.com”.
      </p>

      <h2>Terms and Conditions</h2>
      <p>
        The term “IT Accurate Training” shall mean IT Accurate Private Limited. No content/material from this site may be
        copied, modified, reproduced, republished, uploaded, transmitted, posted, or distributed in any form without
        any prior permission from IT Accurate Training. All rights not expressly granted herein are reserved.
      </p>
      <p>
        The name of IT Accurate Training and logo are trademarks that belong to IT Accurate Private Limited. These trademarks
        cannot be used in any manner without prior written consent from IT Accurate.
      </p>
      <p>
        Unauthorized use of the content/materials appearing on the site may violate copyright and other applicable laws.
        Such use could result in penalties.
      </p>
      <p>
        IT Accurate may terminate a user’s access to the site at any time for any reason. IT Accurate may change or
        discontinue any aspect of the website at any time, including the content/materials and features.
      </p>
      <p>
        IT Accurate shall not be liable for damages of any kind, including direct, consequential, or incidental damages
        (including, but not limited to, damages for lost profits, interruption of business, and loss of information)
        emerging out of the use of or inability to use the website/information provided on the site, or for any reason
        whatsoever.
      </p>
      <p>
        IT Accurate Training shall have no responsibility for any damage to the user’s device or any loss of data that may
        result from downloading content or any materials on the website.
      </p>
      <p>
        IT Accurate Training does not warrant that any of the content on the site is accurate, complete, or current, though
        IT Accurate Training obtains information from reliable sources. IT Accurate Training may make changes to the website
        or these terms without any notice. By using this website, you agree to be bound by the then current version of
        these terms.
      </p>
      <p>
        The site provides links to third-party websites/platforms and access to content materials. IT Accurate Training is
        not responsible for the availability of, or content provided on the third-party websites. Before using such
        sites, IT Accurate Training encourages users to read their policies. The user shall bear all the risks associated
        with the use of such content. IT Accurate Training is not responsible for any loss or damages the user may incur.
      </p>
      <p>
        Posting content through the platforms, you grant IT Accurate Training a worldwide, non-exclusive, royalty-free
        license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit,
        display, and distribute (your name and image) in any and all media or distribution methods (existing now or
        later developed) used for marketing purposes.
      </p>
      <p>
        You agree to pay the fees for content that you purchase, and you authorize us to charge your debit or credit
        card or process other means of payment (such as Boleto, SEPA, direct debit, or mobile wallet) for those
        training fees. IT Accurate Training works with payment service providers to offer you the most convenient payment
        methods in your country and to keep your payment information secure. We may update your payment methods using
        the information provided by our payment service providers.
      </p>
    </div>
  );
};

export default Terms;
