import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";
import MarqueeLogos from "../components/MarqueeLogos";

const DevOpsPage = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: "i3HdVH6gdfE", // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: "i3HdVH6gdfE", // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "What is DevOps?",
        "DevOps course",
        "DevOps certification",
        "DevOps jobs in India",
        "DevOps jobs in Mumbai",
      ],
    },
    {
      title: "Assessment and Planning",
      points: [
        "Evaluate current development and operational processes.",
        "Identify bottlenecks, pain points, and areas for improvement.",
        "Define business goals and align them with DevOps objectives.",
        "Develop a comprehensive plan for implementing DevOps practices.",
      ],
    },
    {
      title: "Culture and Collaboration",
      points: [
        "Foster a culture of collaboration and communication between development and operations teams.",
        "Encourage the adoption of shared responsibilities and goals.",
        "Implement training programs to upskill teams on DevOps principles.",
        "Establish cross-functional teams to promote collaboration.",
      ],
    },
    {
      title: "Infrastructure as Code (IaC)",
      points: [
        "Introduce Infrastructure as Code (IaC) to automate the provisioning and management of infrastructure.",
        "Utilize tools like Terraform or Ansible to define and deploy infrastructure.",
        "Version control infrastructure code for traceability and repeatability.",
        "Automate testing and validation of infrastructure changes.",
      ],
    },
    {
      title: "Continuous Integration (CI)",
      points: [
        "Implement CI practices to automate the build and integration of code changes.",
        "Use tools such as Jenkins, GitLab CI, or Travis CI for automated builds.",
        "Run automated tests during the CI process to catch and address issues early.",
        "Establish a single source of truth for the code repository.",
      ],
    },
    {
      title: "Continuous Deployment (CD)",
      points: [
        "Extend automation to deployment processes with Continuous Deployment.",
        "Implement tools like Kubernetes, Docker, or OpenShift for containerization and orchestration.",
        "Automate the deployment pipeline to enable rapid and reliable releases.",
        "Monitor and analyze application performance in real-time.",
      ],
    },
    {
      title: "Monitoring and Feedback",
      points: [
        "Implement robust monitoring solutions for applications and infrastructure.",
        "Set up alerting systems to notify teams of potential issues.",
        "Gather feedback from monitoring to continuously improve processes.",
        "Conduct regular retrospectives to identify areas for refinement in the DevOps pipeline.",
      ],
    },
    {
      title: "Resume Preparation",
      points: [
        "Include keywords.",
        "How to prepare resume.",
        "How to prepare resume for freshers.",
        "Show impact.",
        "Include soft skills.",
        "Be unique.",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Cloud Computing Introduction",
      subpoints: [
        "What is Cloud Computing",
        "Why Cloud Computing?",
        "Advantages of Cloud Computing",
        "What is Private Cloud",
        "What is Public Cloud",
        "What is Hybrid Cloud",
        "What is Software as a Service (SaaS)",
        "What is Platform as a Service(PaaS)",
        "What is Infrastructure as a Service (IaaS)",
        "Basics of Linux",
      ],
    },
    {
      title: "Introduction to AWS Services",
      subpoints: [
        "Amazon Elastic Compute Cloud(EC2)",
        "Amazon Simple Storage Service (S3)",
        "Elastic Block Storage (EBS)",
        "Elastic Load Balancing (ELB)",
        "Amazon Relational Database Service (RDS) Amazon DynamoDB",
        "Auto Scaling",
        "Amazon ElastiCache",
        "Identity and Access Management (IAM)",
        "Virtual Private Cloud (VPC)",
        "loud Formation",
        "Simple Email Services (SES)",
        "Simple Queue Services (SQS)",
        "Simple Notification Services (SNS)",
        "Server less computing (Lambda)",
        "Import/Export (Snowball)",
        "Code Commit",
        "Cloud Trail",
        "Elastic Beanstack",
        "CloudFront",
        "Route-53",
        "Glacier",
        "Key Management Service (KMS)",
      ],
    },
    {
      title: "Understanding Object Storage",
      subpoints: [
        "Explain AWS Storage",
        "AWS Simple Storage Service – S3",
        "Creating an AWS S3 bucket",
        "AWS Storage Gateway",
        "What is Command Line Interface (CLI)",
        "What is Amazon S3",
        "Understanding Amazon Glacier storage for long-term data backup and archiving",
        "Learn Amazon Snowball for data import/export",
      ],
    },
    {
      title: "Understanding Database services",
      subpoints: [
        "What is Elastic Block Storage (EBS)",
        "Relational Data bases Amazon RDS and Amazon Aurora",
        "What is Amazon DynamoDB – No SQL Data base",
        "Data warehousing Product – Amazon Redshift",
        "Memory data Store – Amazon ElasticCache",
        "Deploying AWS database migration service",
      ],
    },
    {
      title: "Auto Scaling",
      subpoints: [
        "Introduction to auto scaling",
        "Auto scaling components",
        "Launch configuration Prerequisites",
        "How to Create Launch configuration.",
        "How to Create Auto Scaling Groups (ASG)",
        "How to Attach & Detach EC2 Instances in ASG",
        "configuring auto scaling policies based on the Load on EC2 instances.",
        "Using Auto scaling with Elastic Load balancer (ELB).",
        "Removing the Instances Temporarily",
        "Suspend and Resume Process",
        "Shut Down – Auto Scaling Process",
        "Monitoring – Auto Scaling Instances",
        "Health Checks",
        "Getting Notifications When ASG Changes",
      ],
    },
    {
      title: "ELB – Elastic Load Balancing",
      subpoints: [
        "What is ELB",
        "How to Create load balancer",
        "What is Internal & External Load balancers",
        "Load balancing protocols",
        "What is Listener Configurations",
        "What is SSL Negotiation Configurations",
        "How to Attach & Detach Subnets",
        "Creating Security groups for the load balancer",
        "Configuring health check for the load balancer",
        "Adding multiple instances to the load balancer",
        "What is Custom Domain Names & Cross-Zone Load Balancing",
        "Explain DNS Failover",
        "What is Sticky Sessions",
        "Perform Monitoring and Logging",
        "What is Connection Draining & ELB traffic logging",
      ],
    },
    {
      title: "Virtual private cloud (vpc)",
      subpoints: [
        "Setting up the different types of networks in AWS",
        "How to Create custom VPC",
        "What is NACLs & Security Groups",
        "How to Create Internet Gateway(IGW)",
        "How to Connect to instances in the gateway",
        "What is Subnets, Route Tables & Association",
        "NAT Instances & NAT-Gateways",
        "DHCP Options Sets & DNS’",
        "VPC Peering",
        "Overview of VPN and its components",
      ],
    },
    {
      title: "Application Services",
      subpoints: [
        "Study about AWS application services",
        "Learn Elastic Beanstalk",
        "Learn Simple Email Services",
        "Learn Simple Notification Service",
        "Learn AWS Lambda",
        "Learn Elastic OpsWorks and CLI",
      ],
    },
    {
      title: "Identity and Access Management(IAM)& Monitoring",
      subpoints: [
        "What is IAM",
        "What is Monitoring",
        "To Create user accounts",
        "To Set up multi factor Authentication (MFA)",
        "IAM Roles & IAM Groups",
        "Cloud watch",
      ],
    },
    {
      title: "Cloud Formation",
      subpoints: [
        "How to Build AWS infrastructure as a code",
        "Understanding Sample templates – Utilization",
        "What is JSON",
      ],
    },
    {
      title: "AWS Migration",
      subpoints: [
        "AWS Cloud Migration",
        "Business driver",
        "Cloud Adoption",
        "Various tools and services in Migration",
        "Types of Migration",
        "Migration Process track",
      ],
    },
    {
      title: "AWS Security",
      subpoints: [
        "Study about AWS Cloud Security",
        "Understanding Security compliance",
        "Logging and monitoring",
        "Security: Using IAM to Manage Access to Amazon RDS Resources",
        "Understanding Native AWS Security",
      ],
    },
    {
      title: "Install DevOps Tools",
      subpoints: [
        "Git",
        "Docker",
        "Selenium",
        "Maven",
        "Jenkins",
        "Puppet",
        "Ansible",
        "Kubernetes",
        "Nagios",
      ],
    },
    {
      title: "Introduction to DevOps",
      subpoints: [
        "What is Software Development",
        "Understanding Software Development Life Cycle",
        "What & Why Devops?",
        "Devops Lifecycle & Tools",
        "Section – Software Version Control",
        "What is software version control",
        "Types of Version control",
        "Create a git project",
        "Create a file and add to git, Edit file, Commit the code",
      ],
    },
    {
      title: "Containerization with Docker",
      subpoints: [
        "Docker Fundamentals",
        "What is Container Orchestration",
        "Docker Volumes",
        "Docker Swarm",
        "Create an image in Docker and run it",
      ],
    },
    {
      title: " Puppet Configuration Management Tool",
      subpoints: [
        "Study about Configuration Management",
        "What is a Puppet?",
        "Puppet Architecture",
        "Setting up Master Slave with Puppet",
        "Learn Puppet Manifests",
        "Learn Puppet Modules",
        "Learn Puppet Commands",
        "Applying configuration using Puppet",
        "Understanding Puppet File Server",
      ],
    },
    {
      title: "Ansible",
      subpoints: [
        "What is Ansible",
        "Configuration,Writing Ansible Playbooks",
        "Ansible based Configuration Management",
        "Ansible Roles",
        "Command Line usage.",
        "Write Ansible playbook",
      ],
    },
    {
      title: "Continuous Testing",
      subpoints: [
        "Basics about the Automating Builds – Maven, Ant",
        "What is Maven?",
        "Running Testing Cases",
      ],
    },
    {
      title: "Jenkins",
      subpoints: [
        "What is Continuous Integration?",
        "What is Jenkins?",
        "Setting up continuous Delivery Pipeline using Jenkins",
        "Building a continuous Delivery Pipeline Using Jenkins",
        "Understanding CI/CD Pipelines",
        "Creating an end to end automated CI/CD Pipeline",
      ],
    },
    {
      title: "Continuous Orchestration with Kubernetes",
      subpoints: [
        "What is Kubernetes",
        "Docker Swarm vs Kubernetes",
        "Explain Kubernetes Architecture",
        "Deploying Kubernetes using Kubeadms",
        "Alternate ways of deploying Kubernetes",
        "Study about YAML Files",
        "Creating a Deployment in Kubernetes using YAML",
        "Services & Ingress in Kubernetes",
      ],
    },
    {
      title: "Continuous Monitoring with Nagios",
      subpoints: [
        "What is Continuous Monitoring",
        "What is Nagios",
        "Explain Nagios Architecture",
        "Monitoring Services in Nagios",
        "Study about NRPE Plugin",
        "Monitoring System Info using NRPE plugins",
      ],
    },
    {
      title: "AWS DevOps Services",
      subpoints: [
        "AWS CodeCommit",
        "AWS CodeDeploy",
        "AWS CodePipeline",
        "AWS CodeBuild",
        "Study about Beanstalk",
      ],
    },
  ];

  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];

  const faqs = [
    {
      question: "What's DevOps?",
      answer:
        "DevOps is a set of practices that aim to automate and integrate the processes of software development and IT operations. It enhances collaboration, accelerates delivery, and improves the overall efficiency of the software development lifecycle.",
    },
    {
      question: "Why is DevOps important?",
      answer:
        "DevOps promotes faster development cycles, increased collaboration, continuous integration/deployment, and better overall software quality, enhancing business agility and competitiveness.",
    },
    {
      question: "What are the key principles of DevOps?",
      answer:
        "Key DevOps principles include automation, collaboration, continuous integration, continuous delivery, and monitoring.",
    },
    {
      question: "What is Continuous Integration (CI)?",
      answer:
        "Continuous Integration is the practice of regularly merging code changes from multiple contributors into a shared repository, ensuring that each integration is verified by automated tests.",
    },
    {
      question: "What is Continuous Deployment (CD)?",
      answer:
        "Continuous Deployment automatically deploys code changes that pass automated testing into production, making the release pipeline more efficient and reducing manual intervention.",
    },
    {
      question: "Explain Infrastructure as Code (IaC).",
      answer:
        "Infrastructure as Code involves managing and provisioning infrastructure through machine-readable scripts, enabling automated and consistent infrastructure deployment.",
    },
    {
      question: "What is Docker?",
      answer:
        "Docker is a containerization platform that allows developers to package applications and their dependencies into standardized units, making it easy to deploy and run applications consistently across different environments.",
    },
    {
      question: "Define Microservices.",
      answer:
        "Microservices is an architectural approach where a software application is divided into small, independent services that can be developed, deployed, and scaled independently, promoting flexibility and maintainability.",
    },
    {
      question: "What is Continuous Monitoring in DevOps?",
      answer:
        "Continuous Monitoring involves tracking and analyzing system performance and application behavior in real-time to identify issues, ensure reliability, and maintain system health.",
    },
  ];
  const whatsData = [
    {
      title: "What is DevOps",
      points: [
        "DevOps is a combination of software development (Dev) and IT operations (Ops). It is an engineering approach aimed at integrating the work of development and operations teams by fostering a culture of collaboration and shared responsibility.",
        "DevOps emphasizes communication and collaboration between development and operations teams to eliminate silos and increase efficiency.",
        "Automation is a key element in DevOps, enabling fast and consistent deployment, testing, and infrastructure management.",
      ],
    },
  ];
  

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devops-page">
      <div className="content-container-devops">
        <div className="devops-circle devops-circle-bottom-left"></div>

        {/* Left Content */}
        <div className="left-content">
  <h1>Best DevOps Training</h1>
  <p>
    Best DevOps Training by IT Accurate offers top-notch DevOps training in Mumbai for both freshers and experienced candidates. With expert guidance and 100% guaranteed placement assistance, our course covers every technical topic in detail, providing you with real-world, hands-on experience.
  </p>
  <p>
    Our practical, job-oriented training program will not only provide you with a certificate but also equip you with skills equivalent to over 2 years of field experience. We value your time, which is why we aim to train you in DevOps in just 5 months.
  </p>

  <h3>Topics covered in detail include:</h3>
  <ul className="points-list">
    <li>
      <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
    </li>
    <li>
      <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
    </li>
    <li>
      <FontAwesomeIcon icon={faBriefcase} /> 6-month internship
    </li>
    <li>
      <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program
    </li>
  </ul>
</div>


        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn DevOps" roadmapData={roadmapData} />
      <Syllabus heading="DevOps Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="DevOps Training" keyFeatures={keyFeatures} />

      {/* <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for DevOps Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the DevOps Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>

        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>DevOps</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>AWS</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <section className="devops-certification-section">
        <h2 className="certification-heading">DevOps Certification</h2>
        <div className="certification-content">
        <div className="certification-points">
  <ul>
    <li>
      IT Accurate training certification will serve as proof that the courses were completed by IT Accurate.
    </li>
    <li>
      The DevOps certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.
    </li>
    <li>
      IT Accurate provides comprehensive guidance for your DevOps global certification, ensuring a 100% passing guarantee in examinations.
    </li>
    <li>
      DevOps Platform Development Certification and various other global exams.
    </li>
  </ul>
</div>

          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Devops Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}

      {/* <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div> */}
      <MarqueeLogos />
    </div>
  );
};

export default DevOpsPage;
