import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/BlogList.css';
import { client } from '../contentful/client';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllEntries = async () => {
      try {
        const entries = await client.getEntries();
        const validEntries = entries.items.filter(
          (post) => post.fields.blogTitle
        );
        setBlogPosts(validEntries);
        console.log(validEntries)
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getAllEntries();
  }, []);

  const formatDate = (createdAt) => {
    const createdDate = new Date(createdAt);
    const today = new Date();
    const diffInTime = today.getTime() - createdDate.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);

    if (diffInDays < 1 && today.getDate() === createdDate.getDate()) {
      return 'Today';
    } else if (diffInDays < 2 && today.getDate() - createdDate.getDate() === 1) {
      return 'Yesterday';
    } else {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return createdDate.toLocaleDateString(undefined, options);
    }
  };

  const limitHeading = (content, charLimit) => {
    if (content.length > charLimit) {
      return content.substring(0, charLimit) + '...';
    }
    return content;
  };

  const limitParagraph = (content, wordLimit) => {
    const plainText = documentToPlainTextString(content || { content: [] }); // Handle missing rich text content
    const words = plainText.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return plainText || 'No description available'; // Fallback if no text
  };

  return (
    <div className="blog-list-container">
      {loading ? (
        <div className="skeleton-card">
          <div className="skeleton-image skeleton-loader"></div>
          <div className="skeleton-title skeleton-loader"></div>
          <div className="skeleton-paragraph skeleton-loader"></div>
          <div className="skeleton-paragraph skeleton-loader"></div>
        </div>
      ) : (
        blogPosts.map((post) => (
          <div className="blog-card" key={post.sys.id}>
            <h2 className="blog-card-title">{limitHeading(post.fields.blogTitle, 50)}</h2>
            <img
              src={post.fields.blogImg?.fields?.file?.url || 'https://via.placeholder.com/400x400'}
              alt="Blog"
              className="blog-card-image"
            />
            <p className="blog-card-date">Posted on: {formatDate(post.sys.createdAt)}</p>
            <p className="blog-card-paragraph">{limitParagraph(post.fields.richtext, 6)}</p>
            <Link to={`/blogDetails/${post.sys.id}`} className="read-more-btn">Read Blog</Link>
          </div>
        ))
      )}
    </div>
  );
};

export default BlogList;
