import React, { useState } from 'react';

const Faq = ({ Faqs }) => {
    const [activeQuestion, setActiveQuestion] = useState(null);

    const handleToggle = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };

    return (
        <div className="faq-section">
            <h1 className='main-heading'>Frequently Asked Questions</h1>
            <div className="faq-container">
                <div className="faq-questions">
                    {Faqs.map((faq, index) => (
                        <div
                            key={index}
                            className={`faq-item ${activeQuestion === index ? 'active' : ''}`}
                        >
                            <div
                                className="faq-question"
                                onClick={() => handleToggle(index)}
                            >
                                <h3>{faq.question}</h3>
                            </div>
                            {activeQuestion === index && (
                                <div className="faq-answer">
                                    <p>{faq.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Faq;
