import React from "react";
import "../css/About.css"; // CSS file for styling
import ContactForm from "../components/ContactForm";
import Certifications from '../components/Certifications';
import MOU from '../components/MouSlider';
import w1 from "../assets/workshop/workshop1.webp";
import w2 from "../assets/workshop/workshop2.webp"; // Example import for workshop 2
import w3 from "../assets/workshop/workshop3.webp"; // Example import for workshop 3
import w4 from "../assets/workshop/workshop4.webp"; // Example import for workshop 4
import w5 from "../assets/workshop/workshop5.webp"; // Example import for workshop 5
import w6 from "../assets/workshop/workshop6.webp";
import w7 from "../assets/workshop/workshop7.jpg";
import w8 from "../assets/workshop/workshop8.jpg";

const AboutUs = () => {
  const workshops = [
    { image: w1, text: "IT Accurate successfully completed a Erp workshop at Lad college" },
    { image: w2, text: "IT Accurate successfully completed a Data analytics workshop at Palloti college" },
    { image: w3, text: "IT Accurate successfully completed a Salesforce workshop at Nit college" },
    { image: w4, text: "IT Accurate successfully completed a Erp workshop at Gh raisoni, college" },
    { image: w5, text: "IT Accurate successfully completed a Data analytics  workshop at Gh raisoni college  " },
    { image: w6, text: "IT Accurate successfully completed a Ai & ml workshop at G.h.raisoni college " },
    { image: w7, text: "IT Accurate  successfully completed a Salesforce workshop at Dr. Ambedkar institute of management studies and research" },
    { image: w8, text: "IT Accurate successfully completed a Sap workshop at National academy of defence production ambajhari nagpur" },
  ];

  return (
    <div className="about-us-container">
      <div className="about-us-left">
        <div className="about-us-header">
          <h1>About Us</h1>
          <p>
            In Thane, IT Accurate is a renowned IT training center that was
            founded in 2012. We are among the top training facilities in central
            India, providing our services to institutions, universities, and
            individual applicants throughout the country.
          </p>
          <p>
            Through our hands-on, career-focused training program, you will gain
            knowledge comparable to at least one year of field experience in
            addition to internationally recognized certifications. Our time is
            just as valuable as yours. Therefore, in addition to technical mock
            interviews, resume preparation, and 100% guaranteed employment aid,
            we offer an industry-based curriculum conducted by trainers with
            industry expertise. Nagpur has access to all international
            certificates in one location. Sapalogy also helps you improve your
            soft skills, such as public speaking, email etiquette,
            communication, the interview process, and HR grooming sessions.
            Using these skills throughout and after the training, you will ace
            the interviews. We take pride in being Nagpur's top
            placement-providing institution, with over 400 placement
            partnerships with IT service.
          </p>
        </div>

        {/* <div className="about-us-reviews">
          <div className="review">
            <img
              src="https://img.icons8.com/color/48/000000/google-logo.png"
              alt="Google reviews"
            />
            <p>
              <strong>4.5</strong> (1038 reviews)
            </p>
          </div>
          <div className="review">
            <img
              src="https://img.icons8.com/color/48/000000/facebook.png"
              alt="Facebook reviews"
            />
            <p>
              <strong>4.7</strong> (312 reviews)
            </p>
          </div>
        </div> */}
      </div>

      <div className="about-us-right">
        <ContactForm title="Get in Touch" submitText="Submit" />
      </div>

      <div className="courses-section">
        <h2>Courses Offered</h2>
        <div className="courses-container">
          <div className="course-card">
            <h3>SAP</h3>
            <p>Master SAP modules to streamline business processes.</p>
          </div>
          <div className="course-card">
            <h3>Salesforce</h3>
            <p>Explore Salesforce CRM solutions and customization.</p>
          </div>
          <div className="course-card">
            <h3>Full Stack Development</h3>
            <p>Build comprehensive front-end and back-end skills.</p>
          </div>
          <div className="course-card">
            <h3>Data Science</h3>
            <p>Learn to analyze and interpret complex data sets.</p>
          </div>
          <div className="course-card">
            <h3>Data Analytics</h3>
            <p>Develop skills in data visualization and analytics tools.</p>
          </div>
          <div className="course-card">
            <h3>Business Analytics</h3>
            <p>Use analytics to drive business decision-making.</p>
          </div>
          <div className="course-card">
            <h3>DevOps</h3>
            <p>Master CI/CD, automation, and collaboration techniques.</p>
          </div>
          <div className="course-card">
            <h3>AI and ML</h3>
            <p>
              Dive into Artificial Intelligence and Machine Learning concepts.
            </p>
          </div>
          <div className="course-card">
            <h3>Python</h3>
            <p>Learn Python for web development, data science, and more.</p>
          </div>
        </div>
      </div>


      <div className="workshops-section">
        <h2>Our Workshops</h2>
        <div className="workshops-container">
          {workshops.map((workshop, index) => (
            <div key={index} className="workshop-image">
              <img src={workshop.image} alt={workshop.text} />
              <p>{workshop.text}</p>
            </div>
          ))}
        </div>
      </div>


      <Certifications />
      <MOU />

    </div>
  );
};

export default AboutUs;
