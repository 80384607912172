import React, { useState, useEffect } from 'react';
import '../css/PopUpForm.css';
import { useForm } from 'react-hook-form';

function ContactForm() {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  // Show the form after 7 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "353dd854-1ca9-4730-8291-1ead344c700d",
          name: data.name,
          phone: data.phone,
          course: data.course,
        }),
      });
      const result = await response.json();
      if (result.success) {
        alert("Form submitted successfully!");
        reset();
        setIsOpen(false);
      } else {
        console.error("Form submission error:", result);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      {isOpen && (
        <div className="popup-form-overlay">
          <div className="popup-form-container">
            <div className="popup-form-header">
              <h2>Get in Touch</h2>
              <button onClick={handleClose} className="popup-cancel-btn">✖</button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="popup-form-group">
                <label>Name:</label>
                <input
                  type="text"
                  placeholder="Your Name"
                  {...register('name', { required: true })}
                />
                {errors.name && <p className="popup-error">Name is required</p>}
              </div>
              <div className="popup-form-group">
                <label>Phone No:</label>
                <input
                  type="tel"
                  placeholder="Your Phone Number"
                  {...register('phone', { required: true, pattern: /^[0-9]{10}$/ })}
                />
                {errors.phone && <p className="popup-error">Enter a valid 10-digit number</p>}
              </div>
              <div className="popup-form-group">
                <label>Select Course:</label>
                <select {...register('course', { required: true })}>
                  <option value="">-- Select a Course --</option>
                  <option value="Salesforce">Salesforce</option>
                  <option value="AWS">AWS</option>
                  <option value="DevOps">DevOps</option>
                  <option value="Python">Python</option>
                  <option value="AI & ML">AI & ML</option>
                  <option value="Data Analytics">Data Analytics</option>
                  <option value="Business Analytics">Business Analytics</option>
                  <option value="Share Market">Share Market</option>
                </select>
                {errors.course && <p className="popup-error">Please select a course</p>}
              </div>
              <button className="popup-submit-btn" type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default ContactForm;
