import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import '../css/Brands.css'; // External CSS file

const RatingCard = () => {
  const brands = [
    { logo: 'https://d1d5cy0fmpy9m8.cloudfront.net/images/1719645020ed2d3.webp', name: 'GoAbroad', rating: 4.5, score: 4.5 },
    { logo: 'https://d1d5cy0fmpy9m8.cloudfront.net/images/1719645156wd2eded.webp', name: 'UrbanPro', rating: 5, score: 5.0 },
    { logo: 'https://cdn.henryharvin.com/gooo1.webp', name: 'GO Overseas', rating: 4.5, score: 4.5 },
    { logo: 'https://d1d5cy0fmpy9m8.cloudfront.net/images/1719645091ff4f.webp', name: 'AmbitionBox', rating: 4.6, score: 4.6 },
    { logo: 'https://d1d5cy0fmpy9m8.cloudfront.net/images/17196452202d2d%20(2).webp', name: 'Google', rating: 5, score: 5.0 },
    { logo: 'https://d1d5cy0fmpy9m8.cloudfront.net/images/171964536633d33.webp', name: 'Glassdoor', rating: 4.6, score: 4.6 },
    { logo: 'https://d1d5cy0fmpy9m8.cloudfront.net/images/171964550223e23e23.webp', name: 'EduGorilla', rating: 4.5, score: 4.5 },
    { logo: 'https://d1d5cy0fmpy9m8.cloudfront.net/images/1719645437d23d3d.webp', name: 'MouthShut', rating: 4.5, score: 4.5 },
  ];

  return (
    <div className="container-brand">
      <h2 className="title">We are one of the Most Trusted Brands!</h2>
      <div className="card-container">
        {brands.map((brand, index) => (
          <div key={index} className="rating-card">
            <div className="logo">
              <img src={brand.logo} alt={brand.name} />
            </div>
            <div className="rating-info">
              <div className="stars">
                {[...Array(Math.floor(brand.rating))].map((_, i) => (
                  <FaStar key={i} className="star-icon" />
                ))}
                {brand.rating % 1 !== 0 && <FaStarHalfAlt className="star-icon" />}
              </div>
              <div className="score">{brand.score}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingCard;
