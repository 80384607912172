import React from 'react';
import Marquee from 'react-fast-marquee';
import '../css/MarqueeLogos.css'; // Custom CSS file for styling

const companyLogos = [
  'https://brandlogo.org/wp-content/uploads/2024/06/Google-Logo.png.webp',
  'https://brandlogo.org/wp-content/uploads/2024/11/IBM-Logo-1972.png.webp',
  'https://brandlogo.org/wp-content/uploads/2024/11/Mastercard-Logo-2016.png.webp',
  'https://brandlogo.org/wp-content/uploads/2024/04/Cognizant-Logo.png.webp',
  'https://brandlogo.org/wp-content/uploads/2024/04/Facebook-2023-Logo.png.webp',
  'https://brandlogo.org/wp-content/uploads/2024/04/Oracle-Logo.png.webp',
  'https://brandlogo.org/wp-content/uploads/2024/10/Amazon-Logo-2000.png.webp',
  'https://logos-world.net/wp-content/uploads/2020/11/Flipkart-Logo.png',
  'https://brandlogo.org/wp-content/uploads/2024/11/Walmart-Logo-2008.png.webp',

];

const MarqueeLogos = () => {
  return (
    <div className="marquee-container">
      <h2 className="marquee-heading">Students Placed in Companies</h2>
      <Marquee speed={150} gradient={true} gradientWidth={50}>
        {companyLogos.map((logo, index) => (
          <img key={index} src={logo} alt={`Company Logo ${index + 1}`} className="logo" />
        ))}
      </Marquee>
    </div>
  );
};

export default MarqueeLogos;
