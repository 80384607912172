import React from 'react';
import '../css/Certifications.css';

const Certifications = () => {
  const certificates = [
    { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3MS_jQ9Z9i3eTzikHM43K3mxGne85p0w4LA&s' },
    { src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZhdARmwtnDjhlgcesmZAC1Tb6K0Ga5XVuLQ&s' },
    { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/ISO_9001-2015.svg/1200px-ISO_9001-2015.svg.png' },
    { src: 'https://soft.nbnfindia.org/Upload/0y8c6mrcMy%20project-1%20(8).png' },
    { src: 'https://5.imimg.com/data5/RN/RN/CP/SELLER-4751185/iso-29990-2010-qms.jpg' },
    { src: 'https://pngimagesfree.com/wp-content/uploads/NSDC-Logo-Vector-cdr.png' },
  ];

  return (
    <div className="certificate-section">
      <h2>Our Certifications</h2>
      <div className="certificate-container">
        {certificates.map((certificate, index) => (
          <div key={index} className="certificate-item">
            <img
              src={certificate.src}
              alt={`Certificate ${index + 1}`}
              className="certificate-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Certifications;
