import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";
import MarqueeLogos from "../components/MarqueeLogos";

const AWS = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'vsYIFUePbn0', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'vsYIFUePbn0', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);

  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What iS AWS?',
        'DevOps course',
        'DevOps certification',
        'DevOps jobs in India',
        'DevOps jobs in Mumbai'
      ]
    },
    {
      title: 'Assessment and Planning',
      points: [
        'Evaluate current infrastructure and identify areas for improvement.',
        'Define business goals and objectives that AWS can address.',
        'Conduct a cost analysis to understand the financial implications of migrating to AWS.',
        'Develop a timeline for migration and set realistic milestones.',
      ]
    },
    {
      title: 'Training and Skill Development',
      points: [
        'Identify the skills and knowledge gaps within the team.',
        'Provide training sessions or certifications for AWS services and tools.',
        'Foster a culture of continuous learning to keep the team updated with the latest AWS advancements.',
        'Encourage hands-on experience through labs and practical exercises.',
      ]
    },
    {
      title: ' Proof of Concept (PoC)',
      points: [
        'Select a small, non-critical workload for initial migration.',
        'Design and implement a proof of concept on AWS to validate feasibility.',
        'Gather feedback and address any challenges encountered during the PoC.',
        'Use the PoC as a learning experience to refine the overall migration strategy.',
      ]
    },
    {
      title: 'Migration and Deployment',
      points: [
        'Prioritize workloads based on complexity, dependencies, and business impact.',
        'Develop a detailed migration plan with a phased approach.',
        'Utilize AWS migration tools and services to streamline the process.',
        'Conduct thorough testing at each stage to ensure functionality and performance.',
      ]
    },
    {
      title: 'Optimization and Cost Management',
      points: [
        'Implement best practices for resource optimization and cost control.',
        'Utilize AWS cost management tools to monitor and analyze spending.',
        'Regularly review and adjust resources based on usage patterns and requirements.',
        'Consider reserved instances, spot instances, and other cost-saving strategies.',
      ]
    },
    {
      title: ' Security and Compliance',
      points: [
        'Implement security best practices and configure AWS security features.',
        'Conduct regular security audits and vulnerability assessments.',
        'Ensure compliance with industry regulations and standards.',
        'Educate the team on AWS security practices and encourage a security-first mindset.',
        
      ]
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.'
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Cloud Computing Introduction',
      subpoints: [
        'Introduction to Cloud Computing, AWS Architecture, AWS Management Console, Setting up  of the AWS Account.',
      ],
    },
    {
      title: 'Amazon EC2 and Amazon EBS',
      subpoints: [
        ' Amazon EC2, Amazon EBS, Demo of AMI Creation, Backup, Restore, EC2 Services and EBS  persistent storage.',
      ],
    },
    {
      title: 'Amazon Storage Services : S3, RRS, CloudWatch',
      subpoints: [
        'AWS Storage Services: S3, RRS & Glacier, Amazon Cloud Watch, Alerts, Notification.',
      ],
    },
    {
      title: 'Scaling and Load Distribution in AWS ',
      subpoints: [
        ' Amazon Scaling Service: ELB and Auto Scaling.',
      ],
    },
    {
      title: 'AWS VPC & Route 53',
      subpoints: [
        'Amazon VPC with subnets , Gateways , Route tables and Amazon Route 53 overview.',
      ],
    },
    {
      title: 'Identity and Access Management Techniques (IAM) and Amazon  Managed Relational Database (RDS)',
      subpoints: [
        'Amazon IAM Overview, Amazon RDS.',
      ],
    },
    {
      title: 'Multiple AWS Services and Managing the Resources’ Lifecycle',
      subpoints: [
        'AWS CloudFront, AWS DynamoDB, AWS Import / Export , Overview of AWS Products such  as Elastic Beanstalk, Cloud Formation, AWS OpsWorks, SES.',
      ],
    },
    {
      title: 'AWS Architecture and Design',
      subpoints: [
        'AWS Backup and DR Setup, AWS High availability Design, AWS Best Practices (Cost  +Security), AWS Calculator & Consolidated Billing.',
      ],
    },
    {
      title: 'Migrating to Cloud & AWS Case Study',
      subpoints: [
        'AWS Cloud Migration guidelines. AWS Case Study.',
      ],
    },
    {
      title: 'Project',
      subpoints: [
        'Hands on Workshop, Q/A, Overview of AWS Certification.',],
    },
    
  ];


  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];


  const faqs = [
    {
      question: "What'is AWS?",
      answer: "Amazon Web Services (AWS) is a cloud computing platform that provides a wide range of on-demand services, including computing power, storage, and databases, over the internet.",
    },
    {
      question: "How do I create an EC2 instance on AWS?",
      answer: "Use the AWS Management Console, select EC2, click “Launch Instance,” choose an Amazon Machine Image (AMI), configure instance details, add storage, configure security groups, and review before launching.",
    },
    {
      question: "What is S3 in AWS?",
      answer: "Amazon S3 (Simple Storage Service) is a scalable object storage service that allows users to store and retrieve data. It’s commonly used for backup, storage of static assets, and hosting static websites.",
    },
    {
      question: "How to secure an S3 bucket in AWS?",
      answer: "Apply proper bucket policies, configure access control lists (ACLs), enable versioning, use encryption (SSE-S3, SSE-KMS), and regularly audit and monitor bucket permissions.",
    },
    {
      question: "What is AWS Lambda?",
      answer: "AWS Lambda is a serverless compute service that lets you run code without provisioning or managing servers. It automatically scales and executes code in response to events.",
    },
    {
      question: "How to set up a VPC in AWS?",
      answer: "In the AWS Management Console, navigate to VPC, click “Start VPC Wizard,” choose a configuration (e.g., VPC with a Single Public Subnet), configure VPC settings, and review before creating.",
    },
    {
      question: "What is an IAM role in AWS?",
      answer: "Identity and Access Management (IAM) roles in AWS define a set of permissions for making AWS service requests. Roles are not associated with a specific user but can be assumed by users, services, or AWS resources.",
    },
    {
      question: "How to enable Multi-Factor Authentication (MFA) on AWS?",
      answer: "In the IAM console, go to &quot;Users,&quot; pick the person, click on &quot;Security credentials,&quot; after which set up MFA by choosing both a hardware or virtual MFA device.",
    },
    {
      question: "What is Amazon RDS?",
      answer: "Amazon RDS (Relational Database Service) is a managed relational database carrier that makes it simpler to installation, operate, and scale a relational database inside the cloud.",
    },
    {
      question: "How to monitor AWS resources?",
      answer: "Use AWS CloudWatch for monitoring and creating alarms, AWS Config for tracking resource changes, and AWS Trusted Advisor for best practices and optimization recommendations.",
    },
  ];

  const whatsData = [
    {
      title: 'What is AWS?',
      points: [
        'Cloud computing platform provided by Amazon.',
        'Offers a wide range of services, including computing power, storage, databases, machine learning, and more.',
        'Allows businesses to scale and innovate without the need for upfront investments in hardware.',
        'Provides reliable and secure infrastructure for hosting applications and services.',
        'Widely used by organizations for cost-effective and flexible IT solutions.',
        'IT Accurate covers all the data analytics skills to get you hired in 2024.',
        'IT Accurate provides AWS training in offline and online modes, with real-time project-based training support.',
        'IT background, non-IT background, freshers, and experienced professionals can start their career in AWS, irrespective of their background.',
        'IT Accurate is the best training institute in Mumbai with 100% job opportunities.',
      ]
    }
  ];
  

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devops-page">
      <div className="content-container-devops">
        <div className="devops-circle devops-circle-bottom-left"></div>

        {/* Left Content */}
        <div className="left-content">
  <h1>Best AWS Training</h1>
  <p>
    Best AWS Training by IT Accurate offers top-quality AWS training in Mumbai for both freshers and experienced candidates, with expert guidance and 100% guaranteed placement assistance. Our AWS Training course covers all technical topics in detail, and students gain real-time hands-on experience.
  </p>
  <p>
    Our practical, job-oriented training program not only provides a certificate but also equips you with knowledge equivalent to over 2+ years of field experience. We value your time as much as our own, and hence, we aim to train you in AWS in just 2 months.
  </p>

  <h3>All the topics will be covered in detail and also include:</h3>
  <ul className="points-list">
    <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
    <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
    <li><FontAwesomeIcon icon={faBriefcase} /> 6-month internship</li>
    <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
  </ul>
</div>



        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn AWS" roadmapData={roadmapData} />
      <Syllabus heading="AWS Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="AWS Training" keyFeatures={keyFeatures} />

      {/* <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for DevOps Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the DevOps Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>

        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>DevOps</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>AWS</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <section className="devops-certification-section">
        <h2 className="certification-heading">AWS Certification</h2>
        <div className="certification-content">
        <div className="certification-points">
  <ul>
    <li>IT Accurate training certification will serve as proof that the courses were completed at IT Accurate.</li>
    <li>The AWS certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
    <li>IT Accurate provides comprehensive guidance for your AWS global certification, ensuring a 100% passing guarantee in examinations such as AWS Certification, AWS Platform Development Certification, and various other global exams.</li>
  </ul>
</div>

          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Devops Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}

      {/* <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div> */}
      <MarqueeLogos />
    </div>
  );
};

export default AWS;
