import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
// import dev1 from '../assets/Projects/Salesforce/1.png';
// import dev2 from '../assets/Projects/Salesforce/2.png';
// import dev3 from '../assets/Projects/Salesforce/3.png';
// import dev4 from '../assets/Projects/Salesforce/4.png';
// import dev5 from '../assets/Projects/Salesforce/5.png';
// import dev6 from '../assets/Projects/Salesforce/6.png';
// import dev7 from '../assets/Projects/Salesforce/7.png';
// import dev8 from '../assets/Projects/Salesforce/8.png';
// import dev9 from '../assets/Projects/Salesforce/9.png';
// import dev10 from '../assets/Projects/Salesforce/10.png';
// import rev1 from '../assets/Reviews/Salesforce/sf_rev1.png';
// import rev2 from '../assets/Reviews/Salesforce/sf_rev2.png';
// import rev3 from '../assets/Reviews/Salesforce/sf_rev3.png';
// import rev4 from '../assets/Reviews/Salesforce/sf_rev4.png';
// import rev5 from '../assets/Reviews/Salesforce/sf_rev5.png';
// import RegularSlider from '../Components/RegularSlider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";

const DevOpsPage = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: "syzh1IxEaak", // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: "syzh1IxEaak", // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);

  const roadmapData = [
    {
      title: "Introduction",
      points: [
        "Salesforce crm",
        "What is salesforce?",
        "Salesforce careers",
        "Salesforce course",
        "Salesforce certification",
        "Salesforce job in India ",
        "Salesforce job in Mumbai",
      ],
    },
    {
      title: "Salesforce Admin",
      points: [
        "Salesforce login.",
        "Salesforce trailhead login.",
        "All the topics of salesforce admin are covered in detail.",
      ],
    },
    {
      title: "Salesforce Development",
      points: [
        "Salesforce developer login.",
        "Basic of C Language.",
        "APEX Programming.",
        "All the topics of salesforce development are covered in detail.",
      ],
    },
    {
      title: "Salesforce Lwc and Integration",
      points: [
        "What is LWC?",
        "Basic HTML",
        "JavaScript",
        "All the topics of salesforce Lwc and Integration are covered in detail.",
      ],
    },
    {
      title: "Salesforce Einstein Bots",
      points: [
        "Building a NLP-Powered chatbot",
        "Dialog flows using no-coding configuration",
        "Out of the box functions using APX class",
        "Deploy your Bot to website",
      ],
    },
    {
      title: "Resume Preparation",
      points: [
        "Include keyword",
        "Show impact",
        "Include soft skill",
        "Be unique",
      ],
    },
    {
      title: "Interview Practice",
      points: [
        "How to do face to face online & offline interview",
        "One on One interview sessions",
        "Group discussion",
        "Grooming session",
        "Research the company and follow ups",
      ],
    },
    {
      title: "Job Opportunities",
      points: [
        "Prepare for your Job search",
        "Ready walkin in India",
        "Tie up references",
        "GET PLACED",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Introduction to Apex Programming.",
      subpoints: [
        "Basic Of C language",
        "What is Cloud Computing",
        "APEX Introduction",
        "APEX Classes",
        "APEX Development Process",
        "APEX Development Tools",
        "APEX Governer Limits",
      ],
    },
    {
      title: "Data Types And Logic Control Statements",
      subpoints: [
        "Overview of Apex Data Types",
        "Primitive Data Types",
        "Non Primitive Datatypes",
        "SObject Data Types",
        "Enum Data Types",
        "Collections Data Types",
        "Apex Operators",
        "Apex Control Statements",
        "Executing Apex Programs",
      ],
    },
    {
      title: "APEX Classes",
      subpoints: [
        "Apex Classes",
        "Access Modifiers",
        "Apex Class Constructors",
        "Apex Class Variables and Methods",
        "Inheritance, Sharing, and Interface",
        "Accessing Apex Class",
        "Apex Keywords and Methods",
      ],
    },
    {
      title: "Implementing SOQL And SOSL Queries",
      subpoints: [
        "Standard Object and Field API Names",
        "Relationships in Apex",
        "SOQL Function, SOQL Bindings and SOQL For Loops",
        "Dynamic Query Creation",
        "Relationship Query and its Types",
        "Child to Parent Querry",
        "Parent to Child Querry",
      ],
    },
    {
      title: "Data Manipulation Language",
      subpoints: [
        " Data Manipulation Language",
        "Loops in DML",
        "Database.DML Options",
        "Transaction Control",
        "Ways to invoke DML Operations",
        "Invoking DML Operations",
      ],
    },
    {
      title: "Trigger In APEX",
      subpoints: [
        "Apex Triggers and its Types",
        "Order of Execution of Trigger",
        "Trigger Syntax and Events",
        "Trigger Context Variables",
        "Bulkify Triggers",
        "Trigger Helper Class Pattern",
        "Best Practice of Trigger",
      ],
    },
    {
      title: "Batch Classes",
      subpoints: [
        "Batch Class In Apex",
        "Batch Class Methods",
        "Schedule Methods In Batch Class",
        "Cronn Expressions",
        "Email Programming",
        "Future Methods",
        "Asynchronous Apex VS Synchronous Apex",
      ],
    },
    {
      title: "Exception Handling",
      subpoints: [
        "Exceptions",
        "Exception Statements",
        "System-Defined Exception",
        "Exception Methods",
        "Catching Different Exception Types",
        "Try catch and finally methods",
      ],
    },
    {
      title: "APEX Testing Essentials",
      subpoints: [
        "Write and run Apex Tests",
        "Create Tests data",
        "Running Test Units",
        "Testing Best Practices",
        "Code Coverage",
        "Test Classes Methods",
      ],
    },
  ];

  const keyFeatures = [
    { text: "152+ Hrs Instructor-Led Training", icon: "fa-chalkboard-teacher" },
    { text: "Certificate Guidance", icon: "fa-certificate" },
    { text: "Mentor Support", icon: "fa-user-friends" },
    { text: "Resume Editing", icon: "fa-file-alt" },
    { text: "52 Hrs Project & Exercises", icon: "fa-tasks" },
    { text: "100% Job Oriented Training", icon: "fa-graduation-cap" },
    { text: "36 Hrs Self-Paced Videos", icon: "fa-video" },
    { text: "Job Assistance", icon: "fa-briefcase" },
    { text: "Free Demo Class Available", icon: "fa-chalkboard" },
    { text: "Affordable Fee Structure", icon: "fa-money-bill-wave" },
    { text: "Flexible Schedule", icon: "fa-calendar-alt" },
    { text: "Completed 1500+ Batches", icon: "fa-users" },
  ];

  const faqs = [
    {
      question: "What is Salesforce?",
      answer:
        "Salesforce is a cloud-based Customer Relationship Management (CRM) platform that helps businesses manage customer interactions, automate sales processes, and improve marketing strategies.",
    },
    {
      question: "What is Salesforce Development?",
      answer:
        "Salesforce Development involves customizing the Salesforce platform to meet specific business needs through coding, integration, and app development",
    },
    {
      question: "What Programming Language is used in Salesforce Development?",
      answer:
        "Apex is the primary programming language used in Salesforce Development, similar to Java, for creating custom logic and functionalities.",
    },
    {
      question: "What is Visualforce in Salesforce?",
      answer:
        "Visualforce is a markup language used to develop custom user interfaces in Salesforce, allowing developers to create custom pages and components.",
    },
    {
      question: "What are Lightning Components?",
      answer:
        "Lightning Components are a modern framework for building reusable components in Salesforce, providing a responsive and efficient user experience.",
    },
    {
      question: "How do I Start Learning Salesforce Devlopement?",
      answer:
        "To start learning Salesforce Development, begin with understanding Salesforce basics, then progress to Apex programming, Visualforce, Lightning Components, and integrations.",
    },
    {
      question: "What is Salesforce DX?",
      answer:
        "Salesforce DX (Developer Experience) is a set of tools and features designed to streamline the development lifecycle on the Salesforce platform, including version control, scratch orgs, and continuous integration.",
    },
    {
      question: "What are Salesforce Triggers?",
      answer:
        "Salesforce Triggers are Apex code that executes before or after specific events occur in Salesforce, such as record insertion, update, deletion, or undeletion. Triggers allow developers to automate business processes and enforce data integrity within Salesforce.",
    },
  ];

  const whatsData = [
    {
      title: "What is Salesforce?",
      points: [
        "Salesforce is the world’s #1 CRM (Customer Relationship Management) platform. It is a software solution that helps marketing, sales, commerce, service, and IT teams connect seamlessly with customers. Its user-friendly design makes it easy to learn with the right training and resources.",
        "The platform is designed to be intuitive, allowing users to quickly pick up skills with the proper training.",
        "Whether you have an IT or non-IT background, are a fresher, or an experienced professional, anyone can start a career in Salesforce.",
        "Begin by learning the fundamentals of the platform, get comprehensive training with IT Accurate, and join user groups and communities. Our placement-focused Salesforce training in Mumbai provides hands-on experience to prepare you for real-world scenarios.",
      ],
    }
    
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //   { Image: dev1, alt: "Award 1" },
  //   { Image: dev2, alt: "Award 2" },
  //   { Image: dev3, alt: "Award 3" },
  //   { Image: dev4, alt: "Award 4" },
  //   { Image: dev5, alt: "Award 5" },
  //   { Image: dev6, alt: "Award 6" },
  //   { Image: dev7, alt: "Award 7" },
  //   { Image: dev8, alt: "Award 8" },
  //   { Image: dev9, alt: "Award 8" },
  //   { Image: dev10, alt: "Award 8" },
  // ];

  // const reviewList = [
  //   { Image: rev1, alt: "rev1" },
  //   { Image: rev2, alt: "rev1" },
  //   { Image: rev3, alt: "rev1" },
  //   { Image: rev4, alt: "rev1" },
  //   { Image: rev5, alt: "rev1" },
  // ]

  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best Salesforce Training</h1>
          <p>
            IT Accurate offers the best Salesforce training in Mumbai for both
            freshers and experienced professionals. Our expert-led training
            program ensures 100% guaranteed placement assistance. The course
            covers all technical aspects in-depth, providing students with
            real-world, hands-on experience.
          </p>
          <br />
          <p>
            With our practical, job-focused approach, you'll gain not just a
            certificate but also the skills equivalent to 2+ years of industry
            experience. We understand the value of your time and aim to equip
            you with comprehensive Salesforce knowledge in just 5 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>
        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap
        heading="Roadmap to Learn Salesforce"
        roadmapData={roadmapData}
      />
      <Syllabus heading="Salesforce Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Salesforce Training" keyFeatures={keyFeatures} />

      <section className="devops-certification-section">
        <h2 className="certification-heading">Salesforce Certification</h2>
        <div className="certification-content">
        <div className="certification-points">
  <ul>
    <li>
      IT Accurate training certification serves as proof that the courses were successfully completed with us.
    </li>
    <li>
      The DevOps certification offered by IT Accurate equips you with valuable skills, enhancing your competitiveness in the job market.
    </li>
    <li>
      IT Accurate provides comprehensive guidance for your DevOps global certification, ensuring a 100% passing guarantee in examinations.
    </li>
    <li>
      Includes certifications for DevOps Platform Development and various other global exams.
    </li>
  </ul>
</div>

          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Salesforce Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}
    </div>
  );
};

export default DevOpsPage;
