// MouSlider.jsx
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/MouSlider.css'; // Import the CSS file

// Import MOU images
import mou1 from '../assets/MOU/mou1.webp';
import mou2 from '../assets/MOU/mou2.webp';
import mou3 from '../assets/MOU/mou3.webp';
import mou4 from '../assets/MOU/mou4.webp';
import mou5 from '../assets/MOU/mou5.webp';

const MouSlider = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const mouData = [
    {
      image: mou1,
      description: 'CONFERENCE MEETING VNIT',
    },
    {
      image: mou2,
      description:
        'HR CONCLAVE ORGANISED BY TULSIRAMJI GAIKWAD PATIL COLLEGE ',
    },
    {
      image: mou3,
      description: 'MOU AT AMBEDKAR COLLEGE',
    },
    {
      image: mou4,
      description: 'MOU AT PRIYADARSHANI COLLEGE HINGNA',
    },
    {
      image: mou5,
      description: 'MOU AT GH RAISONI COLLEGE',
    },
  ];

  return (
    <div className="mou-slider-container">
      <h2 className="mou-slider-heading">Memorandum of understanding
      </h2>
      <Slider {...sliderSettings}>
        {mouData.map((item, index) => (
          <div key={index} className="mou-slider-item">
            <img src={item.image} alt={`MOU ${index + 1}`} className="mou-slider-image" />
            <p className="mou-slider-description">{item.description}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MouSlider;
