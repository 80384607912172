import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";
import MarqueeLogos from "../components/MarqueeLogos";

const AWS = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);



  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'He_6YxOfQZQ', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
               event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'He_6YxOfQZQ', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is Business analytics.',
        'Business analytics course.',
        'Business analytics certification.',
        'Business analytics jobs in india.',
        'Business analytics jobs in Mumbai.'
      ]
    },
    {
      title: 'DEFINE OBJECTIVES AND SCOPE',
      points: [
        'Clearly outline the business goals and objectives that analytics will address.',
        'Identify the key performance indicators (KPIs) and metrics relevant to your objectives.',
        'Define the scope of the analytics initiative, specifying the departments or processes it will cover.'
      ]
    },
    {
      title: 'Data Collection and Integration',
      points: [
        'Assess the existing data sources and identify gaps or areas for improvement.',
        'Develop a strategy for collecting and integrating relevant data from various sources.',
        'Ensure data quality and consistency to enhance the reliability of analytics results.'
      ]
    },
    {
      title: 'Implement Data Governance and Security',
      points: [
        'Establish data governance policies to maintain data integrity and compliance.',
        'Implement robust security measures to protect sensitive business information.',
        'Define roles and responsibilities for data access, usage, and monitoring.'
      ]
    },
    {
      title: 'CHOOSE ANALYTICS TOOLS & technologies',
      points: [
        'Evaluate and select analytics tools and technologies that align with business requirements.',
        'Consider factors such as scalability, ease of use, and compatibility with existing systems.',
        'Provide training for the team to ensure proficiency in using chosen tools.'
      ]
    },
    {
      title: ' DEVELOP ANALYTICAL MODELS & ALGORITHMS',
      points: [
        'Engage data scientists and analysts to develop analytical models based on business needs.',
        'Utilize statistical and machine learning techniques to extract insights from the data.',
        'Test and refine models iteratively to improve accuracy and relevance.'
      ]
    },
    {
      title: 'Visualization and Reporting',
      points: [
        'Implement data visualization tools to communicate insights effectively.',
        'Create dashboards and reports that are user-friendly and cater to different stakeholder needs.',
        'Foster a culture of data-driven decision-making by making analytics results easily accessible.'
     
      ]
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.'
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Statistics',
      subpoints: [
        'Stats Definition: I. Descriptive II. Inferential',
        'Population Data vs sample Data: Sampling technics',
        'Variables: Types of variable',
        'Measure of center tendency',
        'Measure of dispersion',
        'Percentile and quartile',
        'Five number summary: Box Plot',
        'Histogram',
        'Distribution',
        'Center limit thermos',
        'Probability',
        'Permutation and combination',
        'Covariance',
        'Pearson correlation',
        'Spearman rank Correlation',
        'Bernoulli’s Distribution, Binominal Distribution, Power law distribution',
        'Hypothesis Testin',
        'P-valueConfidence Interval',
        'Significate value',
        'Interview Questions',
      ],
    },
    {
      title: 'Excel',
      subpoints: [
        'Introduction of Excel: file format',
        'Excel formatting: conditional formatting',
        'Data validation',
        'Pivot table and charts',
        'Excel functions',
        'Data Visualization techniques',
        'Data Analysis Tool',
        'Interview Questions',
      ],
    },
    {
      title: 'MYSQL',
      subpoints: [
        'Introduction of database and Data types: different file system',
        'Types of databases',
        'MySQL Introduction and installation',
        'Creating and managing database, table',
        'Types of command in SQL: DDL, DML, DCL',
        'Data Importing from spreadsheet',
        'Working on joints in MySQL',
        'Date-Time function in MYSQL',
        'Querying and manipulation of data',
      ],
    },
    {
      title: 'Understanding Database services',
      subpoints: [
        'What is Elastic Block Storage (EBS)',
        'Relational Data bases Amazon RDS and Amazon Aurora',
        'What is Amazon DynamoDB – No SQL Data base',
        'Data warehousing Product – Amazon Redshift',
        'Memory data Store – Amazon ElasticCache',
        'Deploying AWS database migration service',
      ],
    },
    {
      title: 'Power BI',
      subpoints: [
        'Introduction of Power BI and its component',
        'Data preparation and transformation using Power Query',
        'Creating and customizing visualization',
        'Building interactive report and dashboard.',
        'Power BI DAX(Data Analysis Expression)',
        'Integration of Power BI with database',
        'Sharing and publishing of Power BI',
      ],
    },
    {
      title: 'Bonus Topics',
      subpoints: [
        'GitHub',
        'Snowflake',
        'AWS',
        'CHATGPT',
        'Interview Preparation',
      ],
    },
  
    {
      title: 'Business Analytics End to End  Project',

    },
  ];


  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];


  const faqs = [
    {
      question: "What's Business Analytics?",
      answer: "Business analytics involves the use of technology and tools to collect, analyze, and present business data, transforming it into actionable insights for decision-making. It encompasses processes like data collection, warehousing, cleansing, and analysis, with a focus on data visualization and reporting.Business analytics helps organizations monitor performance, identify trends, and make informed decisions, ultimately contributing to improved operational efficiency and strategic planning. Specialized BI tools play a key role in streamlining these processes, enabling businesses to extract valuable information from their data for better decision support.",
    },
    {
      question: "What problems does Business Analytics solve?",
      answer: "Business analytics addresses various challenges within organizations, including data silos, inefficient reporting processes, and difficulty in extracting actionable insights from vast datasets. BI solutions provide a unified view of data from different sources, promoting informed decision-making.They mitigate issues related to data quality by facilitating cleansing and transformation. Business analytics tools also enhance reporting and visualization, making it easier for stakeholders to interpret complex data. Additionally, Business analytics aids in identifying trends, patterns, and areas for improvement, fostering more agile and data-driven strategies within businesses.",
    },
    {
      question: "Why does bi intrest you?",
      answer: "As a gadget mastering version, I don’t have personal hobbies or feelings. However, the importance of Business Intelligence (BI) lies in its capacity to empower organizations with valuable insights from records.BI permits information-pushed selection-making, improves operational efficiency, and helps corporations stay aggressive in a swiftly changing panorama. Its impact extends throughout various sectors, contributing to strategic planning and fostering a lifestyle of continuous improvement. The systematic method of BI aligns well with the evolving needs of present day organizations, making it a pivotal component of organizational success.",
    },
    {
      question: "What is data warehousing?",
      answer: "As a machine learning model, my focus is not on personal interests or emotions. Instead, the true significance of Business analytics lies in its power to equip businesses with invaluable insights drawn from data.Through data-driven decision-making, Business analytics boosts operational efficiency and helps companies maintain a competitive edge in an ever-changing landscape. Its impact spans across a variety of industries, driving strategic planning and fostering a culture of constant improvement. The systematic approach of BI is perfectly in line with the evolving demands of modern businesses, making it a crucial element of organizational success.",
    },
    {
      question: "What is data cube?",
      answer: "A data cube is a multidimensional representation of data in a three-dimensional or higher-dimensional space. It allows for the efficient and intuitive exploration of data by organizing information along multiple dimensions, such as time, geography, and product categories. Each cell in the cube contains a summary or aggregation of data corresponding to specific combinations of these dimensions.Data cubes are commonly used in Business Intelligence and data warehousing to facilitate complex analysis, reporting, and decision-making by providing a structured and comprehensive view of data relationships across various dimensions.",
    },
    {
      question: "Describe a good data visualization?",
      answer: "A good data visualization effectively communicates complex information in a clear and visually appealing manner. It should be intuitive, allowing viewers to quickly grasp key insights without unnecessary complexity.The choice of visual elements, such as charts or graphs, should align with the nature of the data and the message intended. Clarity in labeling, color usage, and design helps guide the audience’s attention and enhances understanding. A well-designed data visualization not only conveys information accurately but also engages the audience, making it easier for them to derive meaningful insights and make informed decisions.",
    },
    {
      question: "What is the demand for business intelligence?",
      answer: "The Global Business Intelligence (BA) Market is anticipated to rise at a considerable rate during the forecast period, between 2023 and 2031. In 2022, the market is growing at a steady rate and with the rising adoption of strategies by key players, the market is expected to rise over the projected horizon.",
    },
    {
      question: "What is future of business intelligence?",
      answer: "According to Gartner, by 2027, generative AI will reduce modernization costs by 70%, thanks to LLM-generated insights on where legacy applications lag. There’s no going back from here. The business intelligence future will have generative AI playing a pivotal role, considering the ease of use it offers.",
    },
    {
      question: "Is business intelligence a good role?",
      answer: "Like most careers in the tech field, the business intelligence career outlook is very bright and progressive. Professionals in business intelligence are highly paid and get lucrative salary packages along with perks. BI career path has various segments, and individuals can choose which BI career they want to pursue",
    },
    {
      question: "Who works in business intelligence?",
      answer: "The term “Business analytics” encompasses a wide range of roles, including customer analysts, data analysts, data engineers, economists and statisticians.",
    },
    {
      question: "What are Business analytical tools used for?",
      answer: "Business analytics tools are all about helping you understand trends and deriving insights from your data so that you can make tactical and strategic business decisions. They also help you idenitfy patterns in the mountains of data your business builds up.",
    },
  ];
  const whatsData = [
    {
      title: 'What is Business Analytics?',
      points: [
        'Business analytics involves analyzing large sets of data to extract meaningful insights and trends.',
        'Decision Support: It provides valuable data to assist in strategic decision-making within an organization.',
        'Predictive Modeling: Uses statistical algorithms and machine learning to predict future business outcomes.',
      ]
    }
  ];
  

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devops-page">
      <div className="content-container-devops">
        <div className="devops-circle devops-circle-bottom-left"></div>

        {/* Left Content */}
        <div className="left-content">
  <h1>Best Business Analytics Training</h1>
  <p>
    The Best Business Analytics Training by IT Accurate offers top-notch Business Analytics training in Mumbai for both freshers and experienced candidates, with expert guidance and 100% guaranteed placement assistance. Our Business Analytics training course covers all technical topics in detail, providing students with real-time hands-on experience.
  </p>
  <p>
    Our practical, job-oriented training program not only provides you with a certificate but also equips you with knowledge equivalent to around 2+ years of field experience. We value your time and aim to train you in Business Analytics in just 3 months.
  </p>

  <h3>All the topics will be covered in detail and will also include:</h3>
  <ul className="points-list">
    <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
    <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
    <li><FontAwesomeIcon icon={faBriefcase} /> 6-month internship</li>
    <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
  </ul>
</div>


        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn Business Analytics" roadmapData={roadmapData} />
      <Syllabus heading="Business Analytics Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Business Analytics Training" keyFeatures={keyFeatures} />

      {/* <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for DevOps Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the DevOps Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>

        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>DevOps</td>
                <td>Starts every week</td>
                <td>Mumbai</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>AWS</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <section className="devops-certification-section">
        <h2 className="certification-heading">Business Analytics Certification</h2>
        <div className="certification-content">
        <div className="certification-points">
  <ul>
    <li>IT Accurate training certification will serve as proof that you have successfully completed the courses at IT Accurate.</li>
    <li>The AWS certification offered by IT Accurate will equip you with valuable skills, enhancing your market competitiveness.</li>
    <li>IT Accurate provides comprehensive support for your AWS global certification, ensuring a 100% passing guarantee in exams like AWS Certification, AWS Platform Development Certification, and other global certifications.</li>
  </ul>
</div>

          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Devops Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}

      {/* <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div> */}
      <MarqueeLogos />
    </div>
  );
};

export default AWS;
