// import React, { useRef } from "react";
import "../../css/DevOps.css";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
// import IMG from '../../assets/logo.svg'
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
// import PageForm from '../../components/PageForm';
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import Faq from "../../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
// import dev1 from '../../assets/Projects/Devops/DEV1.png';
// import dev2 from '../../assets/Projects/Devops/DEV2.png';
// import dev3 from '../../assets/Projects/Devops/DEV3.png';
// import dev4 from '../../assets/Projects/Devops/DEV4.png';
// import dev5 from '../../assets/Projects/Devops/DEV5.png';
// import dev6 from '../../assets/Projects/Devops/DEV6.png';
// import dev7 from '../../assets/Projects/Devops/DEV7.png';
// import dev8 from '../../assets/Projects/Devops/DEV8.png';
// import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
// import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
// import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
// import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
// import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
// import SideDropdown from '../../components/SideDropdown';
import MarqueeLogos from "../../components/MarqueeLogos";
import ContactForm from "../../components/ContactForm";
const SAPABAP = () => {



  const roadmapData = [
    {
      title: "Introduction to Power BI",
      points: [
        "What is Power BI?",
        "Understanding Power BI Desktop and Power BI Service",
        "Power BI architecture",
        "Downloading and installing Power BI",
        "Power BI certification",
        "Power BI jobs in India",
        "Power BI jobs in Mumbai",
      ],
    },
    {
      title: "Data Preparation and Data Sources",
      points: [
        "Connect to various data sources (Excel, SQL, etc.)",
        "Data transformation with Power Query",
        "Data modeling basics",
        "Cleaning and shaping data",
      ],
    },
    {
      title: "Data Modeling and DAX (Data Analysis Expressions)",
      points: [
        "Create relationships between tables",
        "Understand and implement DAX functions",
        "Use calculated columns and measures",
        "Optimize data models for performance",
      ],
    },
    {
      title: "Data Visualization in Power BI",
      points: [
        "Create reports and dashboards",
        "Use various visualizations (charts, tables, maps, etc.)",
        "Customize visual elements for better storytelling",
        "Best practices for effective visualization",
      ],
    },
    {
      title: "Power BI Service and Sharing Reports",
      points: [
        "Publish reports to Power BI Service",
        "Understand Power BI workspaces",
        "Share reports and dashboards with others",
        "Implement row-level security (RLS)",
      ],
    },
    {
      title: "Power BI Advanced Features",
      points: [
        "Learn Power BI embedded analytics",
        "Create custom visuals using Python and R",
        "Automate data refresh and schedule reports",
      ],
    },
    {
      title: "Power BI Integration and Power Apps",
      points: [
        "Integrate Power BI with Power Apps and Power Automate",
        "Understand Power BI REST API",
        "Enable real-time dashboards",
      ],
    },
    {
      title: "Resume Preparation for Power BI Roles",
      points: [
        "Include relevant Power BI skills and projects",
        "Optimize resume with Power BI keywords",
        "Showcase your data visualization and DAX skills",
        "Highlight soft skills and problem-solving abilities",
        "Be unique and concise",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Introduction to Power BI",
      subpoints: [
        "What is Power BI?",
        "History & Evolution of Power BI",
        "Understanding Power BI Desktop and Power BI Service",
        "Power BI Architecture",
        "Installing and setting up Power BI Desktop",
        "Power BI vs other BI tools",
      ],
    },
    {
      title: "Data Preparation and Data Sources",
      subpoints: [
        "Introduction to Data Sources",
        "Connecting to Data Sources (Excel, SQL, Web, etc.)",
        "Using Power Query for Data Transformation",
        "Data Cleansing and Shaping",
        "Data Types and Formatting",
        "Data Load vs Direct Query Mode",
        "M Language Basics",
      ],
    },
    {
      title: "Data Modeling and DAX (Data Analysis Expressions)",
      subpoints: [
        "Introduction to Data Modeling",
        "Creating Relationships Between Tables",
        "Star Schema vs Snowflake Schema",
        "Introduction to DAX",
        "DAX Functions: SUM, COUNT, IF, CALCULATE, etc.",
        "Calculated Columns vs Measures",
        "Date and Time Intelligence",
        "DAX Optimization Techniques",
      ],
    },
    {
      title: "Data Visualization in Power BI",
      subpoints: [
        "Introduction to Visualizations",
        "Working with Charts: Bar, Line, Pie, etc.",
        "Creating Tables, Matrices, and Cards",
        "Using Maps and Geo-spatial Visuals",
        "Customizing Visuals for Effective Storytelling",
        "Using Slicers, Filters, and Drill-through",
        "Bookmarks and Selections",
        "Conditional Formatting and KPI Indicators",
      ],
    },
    {
      title: "Power BI Service and Sharing Reports",
      subpoints: [
        "Introduction to Power BI Service",
        "Publishing Reports to Power BI Service",
        "Creating and Managing Workspaces",
        "Collaborating with Teams",
        "Sharing Dashboards and Reports",
        "Row-Level Security (RLS)",
        "Data Gateways and Scheduled Refresh",
      ],
    },
    {
      title: "Power BI Advanced Analytics",
      subpoints: [
        "Introduction to Power BI AI Visuals",
        "Using Q&A (Natural Language Queries)",
        "Creating Forecasts and Trend Analysis",
        "Decomposition Tree and Key Influencers Visual",
        "Integrating Python and R for Advanced Analytics",
        "Time Series Analysis",
      ],
    },
    {
      title: "Power BI Integration and Power Platform",
      subpoints: [
        "Integrating Power BI with Power Apps",
        "Automating Workflows using Power Automate",
        "Embedding Power BI Reports in Web Applications",
        "Introduction to Power BI REST API",
        "Connecting Power BI to SharePoint and Dynamics 365",
      ],
    },
    {
      title: "Power BI Performance Optimization",
      subpoints: [
        "Optimizing Data Models",
        "Improving DAX Query Performance",
        "Using Aggregations for Large Datasets",
        "Best Practices for Report Design",
        "Memory and Storage Optimization",
      ],
    },
    {
      title: "Power BI Administration",
      subpoints: [
        "Admin Roles in Power BI",
        "Power BI Tenant Settings",
        "Monitoring and Auditing Power BI Usage",
        "Governance and Compliance",
        "Licensing Models (Pro, Premium, Embedded)",
      ],
    },
    {
      title: "Resume Preparation for Power BI Careers",
      subpoints: [
        "Highlighting Power BI Skills and Projects",
        "Using Keywords in Resume",
        "Showcase Data Visualization and DAX Skills",
        "Preparing for Power BI Interviews",
        "Building an Impressive Portfolio",
        "Soft Skills for BI Professionals",
      ],
    },
  ];

  const faqs = [
    {
      question: "What is Power BI?",
      answer:
        "Power BI is a business analytics tool developed by Microsoft that allows users to visualize data, create reports, and share insights across their organization.",
    },
    {
      question: "What are the different components of Power BI?",
      answer:
        "Power BI consists of Power BI Desktop, Power BI Service (cloud-based), Power BI Mobile, and Power BI Report Server.",
    },
    {
      question: "What data sources can Power BI connect to?",
      answer:
        "Power BI can connect to various data sources including Excel, SQL Server, SharePoint, Azure, Salesforce, and many more.",
    },
    {
      question: "What is DAX in Power BI?",
      answer:
        "DAX (Data Analysis Expressions) is a formula language used in Power BI to create custom calculations and expressions for reports and dashboards.",
    },
    {
      question: "How do you create a report in Power BI Desktop?",
      answer:
        "To create a report, import data, use Power Query for data transformation, build a data model, and add visualizations to the report canvas.",
    },
    {
      question: "What is Power Query in Power BI?",
      answer:
        "Power Query is a data transformation tool within Power BI used for data cleansing, shaping, and combining data from different sources.",
    },
    {
      question: "What is the difference between Direct Query and Import Mode?",
      answer:
        "Direct Query retrieves data in real-time from the data source, while Import Mode loads data into Power BI, offering better performance for static data.",
    },
    {
      question: "How can you share a Power BI report?",
      answer:
        "Reports can be shared via Power BI Service by publishing them to workspaces, sharing dashboards, or embedding reports in applications.",
    },
    {
      question: "What are measures and calculated columns in Power BI?",
      answer:
        "Measures are dynamic calculations used in reports, while calculated columns are static calculations added to a table in the data model.",
    },
    {
      question: "What is Row-Level Security (RLS) in Power BI?",
      answer:
        "RLS restricts data access for specific users by defining roles and security filters within the Power BI report.",
    },
  ];

  const whatsData = [
    {
      title: "What is Power BI?",
      points: [
        "Power BI is a powerful business analytics tool developed by Microsoft that enables users to connect to various data sources, transform data, and create interactive visualizations, reports, and dashboards.",
        "Power BI allows organizations to make data-driven decisions by providing insights through data visualization, predictive analysis, and interactive dashboards.",
        "There are multiple components in Power BI: Power BI Desktop for report creation, Power BI Service for cloud-based sharing, and Power BI Mobile for accessing reports on the go.",
        "IT Accurate offers comprehensive Power BI training in both offline and online modes, covering everything from data preparation to advanced reporting techniques using DAX and Power Query.",
        "Professionals from IT, finance, marketing, and even non-technical backgrounds can start their career in data analytics using Power BI, making it accessible to a wide range of learners.",
        "IT Accurate is the best training institute in Mumbai, offering hands-on training and 100% job assistance for those looking to enhance their career in data analytics.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //     { Image: dev1, alt: "Award 1" },
  //     { Image: dev2, alt: "Award 2" },
  //     { Image: dev3, alt: "Award 3" },
  //     { Image: dev4, alt: "Award 4" },
  //     { Image: dev5, alt: "Award 5" },
  //     { Image: dev6, alt: "Award 6" },
  //     { Image: dev7, alt: "Award 7" },
  //     { Image: dev8, alt: "Award 8" },
  // ];

  // const reviewList = [
  //     { Image: rev1, alt: "rev1" },
  //     { Image: rev2, alt: "rev1" },
  //     { Image: rev3, alt: "rev1" },
  //     { Image: rev4, alt: "rev1" },
  //     { Image: rev5, alt: "rev1" },
  // ]
  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  return (
    <div className="devops-page">
      {/* <SideDropdown roadmapData={modulesData} /> */}
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best Power BI Training</h1>
          <p>
            IT Accurate offers the best Power BI training in Mumbai for both
            freshers and experienced professionals, featuring expert guidance
            and a 100% job placement guarantee. Our Power BI course covers all
            essential topics, ensuring students gain real-time, hands-on
            experience with business intelligence and data visualization.
          </p>
          <br />
          <p>
            Our practical, job-oriented training program not only provides a
            certification but also equips you with skills equivalent to 2+ years
            of industry experience. We value your time, which is why our Power
            BI training is designed to be completed in just 3 months, focusing
            on practical and project-based learning.
          </p>

          <ul className="points-list">
            <li>Data Modeling and DAX Formulas - 1 month</li>
            <li>Power Query and Data Transformation - 1 month</li>
            <li>Project-based Report Development - 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation and
              portfolio building
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview
              preparation and mock interviews
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-month internship with
              live projects
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job placement
              assistance
            </li>
          </ul>
        </div>

        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>
      {/* <div className="video-section">
                <div className="video-container" controls ref={videoRef}></div>
            </div> */}
      <WhatsDevOps whatsData={whatsData} />

      <Roadmap heading="Roadmap to Learn Power BI" roadmapData={roadmapData} />
      <Syllabus heading="Power BI Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Power BI Training" keyFeatures={keyFeatures} />
      
      <section className="devops-certification-section">
        <h2 className="certification-heading">Power BI CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                IT Accurate training certification will serve as proof that you
                have completed the comprehensive Power BI training program.
              </li>
              <li>
                The Power BI certification provided by IT Accurate equips you
                with in-demand data visualization and analytics skills,
                enhancing your job prospects in data analysis and business
                intelligence.
              </li>
              <li>
                IT Accurate offers detailed guidance for Power BI global
                certifications, ensuring a 100% passing guarantee in exams such
                as Microsoft Power BI Data Analyst (PL-300) Certification, and
                other recognized global certifications.
              </li>
            </ul>
          </div>

          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* <Roadmap heading="SAP modules" roadmapData={modulesData} /> */}

      {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
                <h2 className="slider-heading">SAP ABAP Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}

      <MarqueeLogos />
    </div>
  );
};

export default SAPABAP;
