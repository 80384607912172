import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Make sure to import useParams
import '../css/BlogList.css';
import { client } from '../contentful/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const BlogDetails = () => {
    const { id } = useParams(); // Get the blog post ID from the URL
    const [blogPost, setBlogPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getBlogPost = async () => {
            try {
                const entries = await client.getEntries({
                    'sys.id': id // Fetch the blog post by ID
                });
                if (entries.items.length > 0) {
                    setBlogPost(entries.items[0]); // Set the first found entry
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        };
        getBlogPost();
    }, [id]);

    return (
        <div>
            {loading ? (
                <section className="skeleton-container">
                    <div className="skeleton-loader skeleton-title"></div>
                    <div className="skeleton-loader skeleton-image"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                    <div className="skeleton-loader skeleton-subheading"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                    <div className="skeleton-loader skeleton-image"></div>
                    <div className="skeleton-loader skeleton-paragraph"></div>
                </section>
            ) : blogPost ? (
                <section className="blog-detail-container" key={blogPost.sys.id}>
                    <h1 className="blog-title">{blogPost.fields.blogTitle}</h1>
                    <img
                        src={blogPost.fields.blogImg?.fields?.file?.url}
                        alt="Blog-image"
                        className="blog-image"
                    />

                    {/* Render rich text content for the first section */}
                    <div className="blog-paragraph">
                        {documentToReactComponents(blogPost.fields.richtext)}
                    </div>

                    <h3 className="blog-subheading">{blogPost.fields.blogt2}</h3>
                    <img
                        src={blogPost.fields.blogImg2?.fields?.file?.url}
                        alt="Blog-image"
                        className="blog-image"
                    />

                    {/* Render rich text content for the second section */}
                    <div className="blog-paragraph">
                        {documentToReactComponents(blogPost.fields.richtext2)}
                    </div>

                    <h3 className="blog-subheading">{blogPost.fields.blogt3}</h3>
                    <img
                        src={blogPost.fields.blogImg3?.fields?.file?.url}
                        alt="Blog-image"
                        className="blog-image"
                    />

                    {/* Render rich text content for the third section */}
                    <div className="blog-paragraph">
                        {documentToReactComponents(blogPost.fields.richtext3)}
                    </div>

                    <h3 className="blog-subheading">{blogPost.fields.blogt4}</h3>
                    <img
                        src={blogPost.fields.blogImg4?.fields?.file?.url}
                        alt="Blog-image"
                        className="blog-image"
                    />

                    {/* Render rich text content for the fourth section */}
                    <div className="blog-paragraph">
                        {documentToReactComponents(blogPost.fields.richtext4)}
                    </div>

                    <h3 className="blog-subheading">Conclusion</h3>
                    {/* Render rich text content for conclusion */}
                    <div className="blog-paragraph">
                        {documentToReactComponents(blogPost.fields.conclusionrichtext)}
                    </div>
                    <img
                        src={blogPost.fields.promotionPoster?.fields?.file?.url}
                        alt="sapalogy-img"
                        className="blog-image"
                    />

                    <p className="blog-paragraph">
                        {blogPost.fields.infoPara2}
                    </p>
                    <div className="contact-itaccurate">
                        <h2>Contact Sapalogy</h2>
                        <div className="contact-social">
                            <p><strong>Instagram:</strong></p>
                            <a href="https://www.instagram.com/sapalogy_pvt.ltd/" target="_blank" rel="noopener noreferrer">https://www.instagram.com/sapalogy_pvt.ltd/</a>
                        </div>

                        <div className="contact-social">
                            <p><strong>Facebook:</strong></p>
                            <a href="https://www.facebook.com/Sapalogy/" target="_blank" rel="noopener noreferrer">https://www.facebook.com/Sapalogy/</a>
                        </div>

                        <div className="contact-social">
                            <p><strong>Website:</strong></p>
                            <a href="https://www.sapalogytraining.com" target="_blank" rel="noopener noreferrer">www.sapalogytraining.com</a>
                        </div>

                        <div className="contact-social">
                            <p><strong>Email:</strong></p>
                            <a href="mailto:info@sapalogytraining.com">info@sapalogytraining.com</a>
                        </div>

                        <div className="contact-social">
                            <p><strong>Contact Number:</strong></p>
                            <a href="tel:+9175978889">+91 75978 8889</a>
                        </div>
                    </div>
                    <div className="blog-paragraph">
                        {documentToReactComponents(blogPost.fields.lastpara)}
                    </div>
                </section>
            ) : (
                <p>No blog post found.</p>
            )}
        </div>
    );
};

export default BlogDetails;
