import React from "react";
import "../css/Footer.css";
import logo from "../assets/it accurate logo.png";

const Footer = () => {
  return (
    <div className="footer">
      <footer>
        <div className="container">
          <div className="footer-box logo-main">
            <img src={logo} alt="" />
            <p>TRAINING AND PLACEMENT CENTER..</p>

            <div className="social-icons">
              <a
                href="https://www.facebook.com/ITAccurate"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://x.com/ITAccurate2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.instagram.com/itaccurate?igsh=MWxsOHhmenV4cjhnZQ%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <div className="footer-box">
            <h3>Explore</h3>
            <ul>
  <li><a href="/about">About Us</a></li>
  {/* <li><a href="/services">Services</a></li> */}
  {/* <li><a href="/courses">Courses</a></li> */}
  <li><a href="/blog">Blog</a></li>
  <li><a href="/contact">Contact Us</a></li>
</ul>

          </div>
          <div className="footer-box">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="/contact">Contact Us</a></li>
              {/* <li>Pricing</li> */}
              <li><a href="/terms">Terms & Conditions</a></li>
              <li><a href="/privacy">Privacy Policy</a></li>
              <li><a href="/refund">Refund Policy</a></li>
            </ul>
          </div>
          <div id="unique" className="footer-box">
            <h3>Have a Question?</h3>
            <ul>
              <li>
                <i className="fa fa-map-marker-alt"></i> 606 B-wing Lokmat
                Bhavan, Nagpur
              </li>
              <li>
                <i className="fa fa-phone-alt"></i> 91-7972849125
              </li>
              <li>
                <i className="fa fa-envelope"></i> info@itaccurate.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>Powered by Sapalogy | &copy; 2024 All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
