import { useState } from "react";
import { ChevronDown, ChevronRight } from "lucide-react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export default function DesktopMenu({ menu }) {
  const [isHover, toggleHover] = useState(false);
  const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(null);
  const [hideTimeout, setHideTimeout] = useState(null);

  const toggleHoverMenu = () => {
    toggleHover(!isHover);
  };

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: { duration: 0.5 },
      display: "block",
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: { duration: 0.5 },
      transitionEnd: { display: "none" },
    },
  };

  const miniSubMenuAnimate = {
    enter: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.4 },
      display: "block",
    },
    exit: {
      opacity: 0,
      x: -10,
      transition: { duration: 0.4 },
      transitionEnd: { display: "none" },
    },
  };

  const hasSubMenu = menu?.subMenu?.length;

  const handleMouseEnter = (index) => {
    if (hideTimeout) {
      clearTimeout(hideTimeout);
    }
    setActiveSubMenuIndex(index);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => setActiveSubMenuIndex(null), 200); // Set delay before hiding
    setHideTimeout(timeout);
  };

  return (
    <motion.li
      className="group/link"
      onHoverStart={toggleHoverMenu}
      onHoverEnd={() => {
        toggleHoverMenu();
        setActiveSubMenuIndex(null); // Close minisubmenus when exiting main menu
      }}
      key={menu.name}
    >
      <span className="flex-center gap-1 hover:bg-white/5 cursor-pointer px-3 py-1 rounded-xl">
        {menu.path ? (
          <Link to={menu.path} className="flex items-center gap-1">
            {menu.name}
            {hasSubMenu &&
              menu.subMenu.some(
                (submenu) =>
                  submenu.minisubMenus && submenu.minisubMenus.length > 0
              ) && (
                <ChevronDown className="mt-[0.6px] group-hover/link:rotate-180 duration-200" />
              )}
          </Link>
        ) : (
          <>
            {menu.name}
            {hasSubMenu &&
              menu.subMenu.some(
                (submenu) =>
                  submenu.minisubMenus && submenu.minisubMenus.length > 0
              ) && (
                <ChevronDown className="mt-[0.6px] group-hover/link:rotate-180 duration-200" />
              )}
          </>
        )}
      </span>

      {hasSubMenu && (
        <motion.div
          className="sub-menu backdrop-blur-sm bg-white/80 rounded-lg p-4 shadow-lg"
          initial="exit"
          animate={isHover ? "enter" : "exit"}
          variants={subMenuAnimate}
        >
          <div
            className={`grid gap-7 ${
              menu.gridCols === 3
                ? "grid-cols-3"
                : menu.gridCols === 2
                ? "grid-cols-2"
                : "grid-cols-1"
            }`}
          >
            {menu.subMenu.map((submenu, i) => (
              <div
                className="relative cursor-pointer"
                key={i}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
              >
                {menu.gridCols > 1 && menu?.subMenuHeading?.[i] && (
                  <p className="text-sm mb-4 text-gray-500">
                    {menu?.subMenuHeading?.[i]}
                  </p>
                )}
                <div className="flex items-center gap-x-4 group/menubox">
                  <div className="w-8 h-8 flex items-center justify-center bg-white/5 text-blue-500 rounded-md group-hover/menubox:bg-blue-300 group-hover/menubox:text-white duration-300">
                    {/* Render logo if logoUrl is provided, otherwise render icon */}
                    {submenu.logoUrl ? (
                      <img
                        src={submenu.logoUrl}
                        alt={submenu.name}
                        className="w-full h-full object-contain rounded-md"
                      />
                    ) : (
                      submenu.icon && <submenu.icon />
                    )}
                  </div>
                  <div>
                    <h6 className="font-semibold text-blue-500 flex items-center">
                      {submenu.path ? (
                        <Link to={submenu.path}>{submenu.name}</Link>
                      ) : (
                        submenu.name
                      )}
                      {/* Show ChevronRight only if minisubMenus exists */}
                      {submenu.minisubMenus &&
                        submenu.minisubMenus.length > 0 && (
                          <ChevronRight className="ml-1 text-black" />
                        )}
                    </h6>
                    <p className="text-sm text-gray-500">{submenu.desc}</p>
                  </div>
                </div>
                {submenu.minisubMenus &&
                  submenu.minisubMenus.length > 0 &&
                  activeSubMenuIndex === i && (
                    <motion.div
                      className={`absolute top-0 mt-2 p-4 bg-[#D9DCEB] rounded-md shadow-lg w-60 ${
                        submenu.name === "SAP"
                          ? "right-full mr-4"
                          : "left-full ml-4"
                      }`}
                      initial="exit"
                      animate="enter"
                      exit="exit"
                      variants={miniSubMenuAnimate}
                      onMouseEnter={() => handleMouseEnter(i)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <ul>
                        {submenu.minisubMenus.map((minisubMenu, j) => (
                          <li
                            key={j}
                            className="py-1 px-2 text-blue-600 rounded hover:bg-white cursor-pointer"
                          >
                            {minisubMenu.path ? (
                              <Link to={minisubMenu.path}>
                                {minisubMenu.name}
                              </Link>
                            ) : (
                              minisubMenu.name
                            )}
                          </li>
                        ))}
                      </ul>
                    </motion.div>
                  )}
              </div>
            ))}
          </div>
        </motion.div>
      )}
    </motion.li>
  );
}
