import React, { useEffect, useRef } from "react";
import "../css/DevOps.css";
import certificateImg from "../assets/Certificates/IT accurate certificate.png";
import Roadmap from "../components/Roadmap";
import WhatsDevOps from "../components/WhatsDevOps";
import Syllabus from "../components/Syllabus";
import KeyFeatures from "../components/KeyFeatures";
import Faq from "../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";
import MarqueeLogos from "../components/MarqueeLogos";

const AIML= () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'y5BqQD_oiiw', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'y5BqQD_oiiw', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is Data analytics ?',
        'Data Analytics course',
        'Data Analytics certification',
        'Data Analytics jobs in india',
        'Data Analytics jobs in Mumbai'
      ]
    },
    {
      title: 'Define Objectives and Scope',
      points: [
        'Clearly outline the goals and objectives of your data analytics initiative.',
        'Identify the key questions you want to answer or the problems you want to solve.',
        'Define the scope of your analysis, including the data sources and time period.',
      ]
    },
    {
      title: 'Data Collection and Integration',
      points: [
        'Identify relevant data sources and gather the necessary datasets.',
        'Cleanse and preprocess the data to ensure accuracy and consistency.',
        'Integrate data from different sources to create a comprehensive dataset for analysis.'
      ]
    },
    {
      title: 'Exploratory Data Analysis (EDA)',
      points: [
        'Perform EDA to understand the characteristics of the data.',
        'Identify patterns, trends, and outliers that may impact the analysis.',
        'Use visualization techniques to communicate insights effectively.',
      ]
    },
    {
      title: 'Modeling and Analysis',
      points: [
        'Select appropriate analytical models based on the nature of the problem.',
        'Train and validate models using historical data.',
        'Apply the models to new data for predictive or descriptive analysis.'
      ]
    },
    {
      title: ' Interpretation and Communication',
      points: [
        'Interpret the results of the analysis in the context of the business objectives.',
        'Clearly communicate findings to both technical and non-technical stakeholders.',
        'Provide actionable insights and recommendations based on the analysis.'
      ]
    },
    {
      title: 'Continuous Improvement and Optimization',
      points: [
        'Establish mechanisms for continuous monitoring of data quality and model performance.',
        'Iterate on the analysis based on feedback and changing business requirements.',
        'Explore new data sources and advanced analytics techniques for continuous improvement.'
      ]
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.'
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Python Basics',
      subpoints: [
        'Introduction of python for data analysis',
        'Data types in python',
        'Pre-define function in python and its use',
        'Input and output method',
        'String data type and its operation',
        'Integer data type and its operation',
        'List data type and its operation',
        'Tuple data type and its operation',
        'Set data type and its operation',
        'Dictionary data type and its operation',
        'If, else and elif condition and nested condition',
        'For loop and while loop and nested loop with condition',
        'User-define function in python',
        'List comprehension and lambda function',
        'File handling in python',
        'Operating System',


      ],
    },
    {
      title: 'Python Library',
      subpoints: [
        'Pandas for data cleaning',
        'Numpy for data manipulation',
        'Matplotlib for data visualization',
        'Seaborn for data visualization',
        'SciPy for statistics',
        'Statistics for data analysis',
        'Sci-kit learn for machine learning',
      ],
    },
    {
      title: 'Statistics',
      subpoints: [
        'Introduction of statistics',
        'Stats Definition: I. Descriptive II. Inferential',
        'Population and sample',
        'Descriptive statistics',
        'Distribution',
        'Type of distribution',
        'Inferential statistics',
        'Hypothesis testing',
        'Type-I error and Type-II error',
        'Data transformation',
      ],
    },
    {
      title: 'MySQL Database',
      subpoints: [
        'Introduction of database',
        'Type of database',
        'MySQL introduction and installation',
        'SQL commands',
        'Database creation',
        'Bulk data load',
        'Basic queries in mysql',
        'Stroe Procedure',
        'View in SQL',
        'Case in SQL',
        'User-define function in database',
        'Condition in database',
        'Table constraint in table',
        'Partition in SQL',
        'Trigger in SQL',
        'CTE, Recursive CTE',
        'Regular expression in SQL',
        'Window function',
        'Window analytical function',
      ],
    },
    {
      title: 'Power BI',
      subpoints: [
        'Introduction of Power BI and its component',
        'Power View: Creating and customizing visualization',
        'Building interactive report and dashboard',
        'Power Query: Data preparation and transformation using Power Query',
        'ETL in Power BI',
        'Power BI DAX: (Data Analysis Expression)',
        'Integration of Power BI with database',
        'Power BI Service: Sharing and publishing',
      ],
    },
    {
      title: 'Advanced Excel',
      subpoints: [
        'Introduction of Excel: file format',
        'Excel formatting: conditional formatting',
        'Data validation',
        'Pivot table and chart',
        'Excel functions',
        'Data Visualization techniques',
        'Data Analysis Tool',
      ],
    },
    {
      title: 'Machine Learning',
      subpoints: [
        'Introduction of machine learning algorithms',
        'Supervised Machine learning',
        'Unsupervised Machine learning',
        'Feature engineering and selection',
        'Model building',
        'Model evaluation and selection',
      ],
    },
    {
      title: 'Bonus Topics',
      subpoints: [
        'GitHub',
        'Snowflake',
        'AWS',
        'CHATGPT',
        'Interview Preparation',
      ],
    },
    {
      title: 'Data Analytics End to End Project',
      subpoints: [
        '',
      ],
    },

  ];

  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  const faqs = [
    {
      question: "What's Data Analyst?",
      answer: "A Data Analyst is a professional who interprets and analyzes complex data sets to provide insights and support decision-making processes within an organization.",
    },
    {
      question: "What skills are required to become a Data Analyst?",
      answer: "Key abilities include talent in programming languages (e.G., Python, R), statistical evaluation, data visualization, SQL, and a strong information of information manipulation and interpretation.",
    },
    {
      question: "What is the role of SQL in Data Analysis?",
      answer: "SQL (Structured Query Language) is crucial for Data Analysts as it allows them to query databases, retrieve relevant data, and perform various data manipulations for analysis.",
    },
    {
      question: "How does Python contribute to Data Analysis?",
      answer: "Python is widely used in Data Analysis for its versatility and rich ecosystem of libraries (e.g., Pandas, NumPy, Matplotlib). It helps in data manipulation, statistical analysis, and creating visualizations.",
    },
    {
      question: "What is the difference between Data Analyst and Data Scientist?",
      answer: "While both roles deal with data, Data Analysts focus on interpreting and analyzing existing data to answer specific business questions, whereas Data Scientists often build predictive models and work on more complex, exploratory tasks.",
    },
    {
      question: "Explain the importance of data visualization in Data Analysis.",
      answer: "Data visualization is crucial for conveying complex information in a clear and understandable way. It helps in identifying patterns, trends, and outliers in the data.",
    },
    {
      question: "What is exploratory data analysis (EDA)?",
      answer: "Exploratory Data Analysis is the process of visually and statistically exploring data sets to understand their characteristics, uncover patterns, and identify potential relationships between variables.",
    },
    {
      question: "How does a Data Analyst contribute to business decision-making?",
      answer: "Data Analysts help companies make knowledgeable selections by presenting insights derived from records evaluation. They perceive traits, styles, and anomalies which can manual strategic picks.",
    },
    {
      question: "What are some common data cleaning techniques?",
      answer: "Regression assessment is a statistical method used to recognize the relationship among based and unbiased variables. It helps in predicting the price of the based variable based totally completely at the values of impartial variables.",
    },
  ];
  const whatsData = [
    {
      title: 'What is Data Analytics?',
      points: [
        'Data analytics involves examining raw data to uncover meaningful and actionable insights.',
        'It helps businesses understand their target market more quickly, boost sales, cut costs, increase revenue, and improve problem-solving.',
        'The demand for data analysts and scientists in India is at an all-time high, with an estimated million job openings by 2026.',
      ]
    }
  ]
  
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devops-page">
      <div className="content-container-devops">
        <div className="devops-circle devops-circle-bottom-left"></div>

        {/* Left Content */}
        <div className="left-content">
  <h1>Best Data Analytics Training</h1>
  <p>
    Best Data Analytics Training by IT Accurate offers top-tier Data Analytics training in Mumbai for both freshers and experienced candidates. We provide expert guidance and 100% guaranteed placement assistance. Our Data Analytics course covers all technical topics in detail, ensuring students gain real-time hands-on experience.
  </p>
  <p>
    Our practical, job-oriented training program not only provides you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time, which is why we have designed the course to train you in Data Analytics in just 5 months.
  </p>

  <h3>All the topics will be covered in detail and will also include:</h3>
  <ul className="points-list">
    <li><FontAwesomeIcon icon={faCheckCircle} /> GitHub</li>
    <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Snowflake</li>
    <li><FontAwesomeIcon icon={faCheckCircle} /> ChatGPT</li>
    <li><FontAwesomeIcon icon={faCheckCircle} /> Interview preparation</li>
    <li><FontAwesomeIcon icon={faFileAlt} /> Case studies</li>
    <li><FontAwesomeIcon icon={faChalkboardTeacher} /> 5+ Capstone projects</li>
    <li><FontAwesomeIcon icon={faBriefcase} /> Industry-level projects</li>
    <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
  </ul>
</div>


        {/* Right Side - Contact Form */}
        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to Learn Data Analytics" roadmapData={roadmapData} />
      <Syllabus heading="Data Analytics Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Data Analytics Training" keyFeatures={keyFeatures} />

      {/* <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for DevOps Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the DevOps Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>

        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>DevOps</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>AWS</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <section className="devops-certification-section">
        <h2 className="certification-heading">Data Analytics Certification</h2>
        <div className="certification-content">
        <div className="certification-points">
  <ul>
    <li>IT Accurate training certification will serve as proof that the courses were completed at IT Accurate.</li>
    <li>The Data Analyst certification offered by IT Accurate will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
    <li>IT Accurate provides comprehensive guidance for your Data Analyst global certification, ensuring a 100% passing guarantee in exams like Data Analytics Certification, Data Analyst Platform Development Certification, and other global certifications.</li>
  </ul>
</div>

          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Devops Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}

      {/* <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div> */}
      <MarqueeLogos />
    </div>
  );
};

export default AIML;
