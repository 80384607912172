import React from 'react';
import Slider from "react-slick";
import '../css/AwardsSlider.css';
import award1 from '../assets/Awards/award1.webp';
import award2 from '../assets/Awards/award2.webp';
import award3 from '../assets/Awards/award3.webp';
import award4 from '../assets/Awards/award4.webp';
import award5 from '../assets/Awards/award5.webp';
import award6 from '../assets/Awards/award6.webp';
import award7 from '../assets/Awards/award7.webp';

const AwardsSlider = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <h2 className="slider-heading">Our Awards</h2>
      <Slider {...sliderSettings}>
        <div className="slider-item">
          <img src={award1} alt="Award 1" className="slider-image" />
          <p className="slider-text">IT Accurate PVT LTD AWARDED AS YCC UPSURGE AWARD</p>
        </div>
        <div className="slider-item">
          <img src={award2} alt="Award 2" className="slider-image" />
          <p className="slider-text">IT Accurate PVT LTD AWARDED AS BEST DATA SCIENCE DELIVERY PARTNER</p>
        </div>
        <div className="slider-item">
          <img src={award3} alt="Award 3" className="slider-image" />
          <p className="slider-text">IT Accurate PVT LTD AWARDED AS BEST ACE BUSINESS AWARD 2020</p>
        </div>
        <div className="slider-item">
          <img src={award4} alt="Award 4" className="slider-image" />
          <p className="slider-text">IT Accurate PVT LTD AWARDED AS BEST BRAND ACADEMY BUSINESS LEADERSHIP</p>
        </div>
        <div className="slider-item">
          <img src={award5} alt="Award 5" className="slider-image" />
          <p className="slider-text">IT Accurate PVT LTD AWARDED AS BEST GREAT INDIAN STARTUP AWARD 2023</p>
        </div>
        <div className="slider-item">
          <img src={award6} alt="Award 6" className="slider-image" />
          <p className="slider-text">IT Accurate PVT LTD AWARDED AS BEST PRATIBIMB AWARD 2020</p>
        </div>
        <div className="slider-item">
          <img src={award7} alt="Award 7" className="slider-image" />
          <p className="slider-text">IT Accurate PVT LTD AWARDED AS BEST GLOBAL STARTUP AWARD</p>
        </div>
      </Slider>
    </div>
  );
};

export default AwardsSlider;
