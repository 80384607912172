// import React, { useRef } from "react";
import "../../css/DevOps.css";
import certificateImg from '../../assets/Certificates/IT accurate certificate.png';
// import IMG from '../../assets/logo.svg'
import Roadmap from "../../components/Roadmap";
import WhatsDevOps from "../../components/WhatsDevOps";
// import PageForm from '../../components/PageForm';
import Syllabus from "../../components/Syllabus";
import KeyFeatures from "../../components/KeyFeatures";
import Faq from "../../components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
// import dev1 from '../../assets/Projects/Devops/DEV1.png';
// import dev2 from '../../assets/Projects/Devops/DEV2.png';
// import dev3 from '../../assets/Projects/Devops/DEV3.png';
// import dev4 from '../../assets/Projects/Devops/DEV4.png';
// import dev5 from '../../assets/Projects/Devops/DEV5.png';
// import dev6 from '../../assets/Projects/Devops/DEV6.png';
// import dev7 from '../../assets/Projects/Devops/DEV7.png';
// import dev8 from '../../assets/Projects/Devops/DEV8.png';
// import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
// import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
// import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
// import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
// import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
// import SideDropdown from '../../components/SideDropdown';
import MarqueeLogos from "../../components/MarqueeLogos";
import ContactForm from "../../components/ContactForm";
const SAPABAP = () => {
  const roadmapData = [
    {
      title: "Introduction to Advanced Excel",
      points: [
        "What is Advanced Excel and how it differs from basic Excel?",
        "Understanding Excel versions (Excel 2016, 2019, Excel 365)",
        "Excel interface and navigation tips",
        "Downloading and installing Excel",
        "Excel certification",
        "Excel jobs in India",
        "Excel jobs in Mumbai",
      ],
    },
    {
      title: "Data Preparation and Data Sources",
      points: [
        "Importing data from various sources (CSV, SQL, Web, etc.)",
        "Using Power Query for data import and cleaning",
        "Data validation and cleaning techniques",
        "Handling large datasets and optimizing data processing",
      ],
    },
    {
      title: "Advanced Formulas and Functions",
      points: [
        "Mastering advanced Excel functions (VLOOKUP, INDEX-MATCH, SUMIFS, etc.)",
        "Using logical functions (IF, AND, OR)",
        "Array formulas and dynamic arrays",
        "Creating and using named ranges",
        "Financial functions and statistical functions in Excel",
      ],
    },
    {
      title: "Data Visualization in Excel",
      points: [
        "Creating charts and graphs (Bar, Line, Pie, etc.)",
        "Working with PivotCharts and PivotTables",
        "Conditional formatting for better data insights",
        "Designing dashboards with Excel",
        "Data storytelling with visualizations",
      ],
    },
    {
      title: "Excel Power Tools",
      points: [
        "Using Power Pivot for advanced data modeling",
        "Working with Power Query for data transformation",
        "Introduction to Power BI and integrating it with Excel",
        "Using Power View for data visualization",
      ],
    },
    {
      title: "Excel Macros and VBA Programming",
      points: [
        "Introduction to Macros and recording simple macros",
        "Editing and writing basic VBA code",
        "Automating repetitive tasks with VBA",
        "Creating custom Excel functions with VBA",
      ],
    },
    {
      title: "Advanced Data Analysis Techniques",
      points: [
        "Using Data Tables for sensitivity analysis",
        "Scenario Manager and Goal Seek for decision making",
        "Conducting regression analysis and data forecasting",
        "Using Solver for optimization problems",
      ],
    },
    {
      title: "Resume Preparation for Excel Roles",
      points: [
        "Highlighting advanced Excel skills and certifications",
        "Showcasing data analysis, visualization, and automation projects",
        "Optimizing resume with Excel-related keywords",
        "Emphasizing problem-solving and data manipulation abilities",
        "Creating a professional portfolio of Excel work",
      ],
    },
  ];

  const syllabus = [
    {
      title: "Introduction to Advanced Excel",
      subpoints: [
        "What is Advanced Excel and how it differs from basic Excel?",
        "Overview of Excel Interface and Navigation Tips",
        "Understanding Excel Versions (Excel 2016, 2019, Excel 365)",
        "Installing and Setting Up Excel",
        "Excel vs Other Spreadsheet Tools",
        "Understanding the Ribbon and Quick Access Toolbar",
      ],
    },
    {
      title: "Data Preparation and Data Sources",
      subpoints: [
        "Connecting to Various Data Sources (CSV, SQL, Web, etc.)",
        "Using Power Query for Data Import and Transformation",
        "Data Cleansing and Shaping in Excel",
        "Understanding Data Types and Formatting in Excel",
        "Working with Large Datasets and Optimizing Performance",
        "Handling Missing Data and Duplicates",
        "Using Data Validation for Consistency",
      ],
    },
    {
      title: "Advanced Formulas and Functions",
      subpoints: [
        "Using Advanced Functions (VLOOKUP, INDEX-MATCH, SUMIFS, etc.)",
        "Mastering Logical Functions (IF, AND, OR)",
        "Array Formulas and Dynamic Arrays",
        "Financial Functions in Excel (NPV, IRR, etc.)",
        "Statistical Functions and Data Analysis Tools",
        "Creating and Using Named Ranges",
        "Working with Text Functions (LEFT, RIGHT, MID, etc.)",
      ],
    },
    {
      title: "Data Visualization in Excel",
      subpoints: [
        "Creating Charts (Bar, Line, Pie, Scatter, etc.)",
        "PivotTables and PivotCharts for Data Analysis",
        "Designing Dashboards with Excel",
        "Working with Conditional Formatting",
        "Creating Interactive Visualizations with Excel Slicers and Timelines",
        "Data Storytelling with Excel Visuals",
        "Using Sparklines and Data Bars",
      ],
    },
    {
      title: "Power Tools in Excel",
      subpoints: [
        "Using Power Pivot for Data Modeling and Analysis",
        "Working with Power Query for Data Transformation",
        "Creating Relationships Between Tables in Power Pivot",
        "Building Reports and Dashboards with Power BI (integrated with Excel)",
        "Using Power View for Interactive Reporting",
      ],
    },
    {
      title: "Excel Macros and VBA Programming",
      subpoints: [
        "Introduction to Macros and Recording Simple Macros",
        "Understanding VBA (Visual Basic for Applications)",
        "Writing Custom VBA Code for Automation",
        "Creating User Defined Functions (UDFs) in Excel",
        "Automating Repetitive Tasks with VBA",
        "Debugging and Error Handling in VBA",
      ],
    },
    {
      title: "Advanced Data Analysis Techniques",
      subpoints: [
        "Using What-If Analysis (Goal Seek, Scenario Manager)",
        "Time Series Analysis and Forecasting",
        "Regression Analysis and Trendlines",
        "Conducting Sensitivity Analysis Using Data Tables",
        "Using Solver for Optimization Problems",
        "Forecasting with Exponential Smoothing and Moving Averages",
      ],
    },
    {
      title: "Excel Integration with Other Tools",
      subpoints: [
        "Integrating Excel with Power BI",
        "Connecting Excel to SharePoint, Google Analytics, and Dynamics 365",
        "Using Excel with SQL Databases for Advanced Reporting",
        "Automating Excel Reports with Power Automate",
        "Embedding Excel Data in Word and PowerPoint",
      ],
    },
    {
      title: "Excel Performance Optimization",
      subpoints: [
        "Optimizing Formulas and Calculations in Excel",
        "Improving Excel File Performance (Reducing File Size)",
        "Using Excel Extracts for Large Datasets",
        "Best Practices for Efficient Workbook Design",
        "Memory and Resource Management in Excel",
      ],
    },
    {
      title: "Resume Preparation for Excel Careers",
      subpoints: [
        "Highlighting Advanced Excel Skills on Your Resume",
        "Showcasing Data Analysis and Visualization Projects",
        "Using Excel Keywords Effectively for Job Search",
        "Preparing for Excel-related Interviews",
        "Building an Impressive Excel Portfolio",
        "Soft Skills for Data Professionals",
      ],
    },
  ];

  const faqs = [
    {
      question: "What is Advanced Excel?",
      answer:
        "Advanced Excel refers to the use of complex functions, formulas, and features in Microsoft Excel to perform advanced data analysis, reporting, and automation. It includes tools like Power Query, Power Pivot, VBA, and more.",
    },
    {
      question:
        "What are the main differences between basic and advanced Excel?",
      answer:
        "Basic Excel covers functions like SUM, AVERAGE, and formatting, whereas Advanced Excel includes advanced formulas (e.g., VLOOKUP, INDEX-MATCH), data analysis tools (e.g., Power Query, Power Pivot), and automation with VBA.",
    },
    {
      question: "How can Excel connect to external data sources?",
      answer:
        "Excel can connect to external data sources like SQL databases, Access, Web, CSV, and cloud-based services such as Google Analytics, SharePoint, and Microsoft Dynamics 365 through Power Query.",
    },
    {
      question: "What are PivotTables in Excel?",
      answer:
        "PivotTables in Excel are powerful tools used for summarizing, analyzing, and visualizing large datasets. They allow you to group data, perform calculations, and display results in a dynamic, interactive format.",
    },
    {
      question: "What is Power Query in Excel?",
      answer:
        "Power Query is a data transformation tool in Excel that helps you import, clean, and transform data from different sources before loading it into Excel for analysis. It's essential for automating data preparation tasks.",
    },
    {
      question: "What is VBA in Excel?",
      answer:
        "VBA (Visual Basic for Applications) is a programming language in Excel that allows you to automate tasks and create custom functions and solutions. It's used to write macros and create more sophisticated automation processes.",
    },
    {
      question:
        "What are advanced Excel functions like VLOOKUP, INDEX, and MATCH?",
      answer:
        "VLOOKUP, INDEX, and MATCH are functions used to look up and return data from large tables. VLOOKUP looks for a value in the first column and returns a corresponding value, while INDEX and MATCH are more flexible and used together for advanced lookups.",
    },
    {
      question: "How can I create interactive dashboards in Excel?",
      answer:
        "Interactive dashboards in Excel can be created using PivotTables, PivotCharts, slicers, and timelines. These elements allow you to create visually appealing and dynamic dashboards that users can interact with for data analysis.",
    },
    {
      question: "What is the Solver add-in in Excel?",
      answer:
        "Solver is an optimization tool in Excel that allows you to find an optimal solution for decision problems. It can be used to maximize or minimize a value in a set of constraints, such as in linear programming or financial modeling.",
    },
    {
      question:
        "What is the difference between Excel Extracts and Live Data Connections?",
      answer:
        "Live Data Connections in Excel refer to real-time connections to external databases or data sources, while Extracts are snapshots of the data that are stored within Excel for faster performance and offline analysis.",
    },
  ];

  const whatsData = [
    {
      title: "What is Advanced Excel?",
      points: [
        "Advanced Excel refers to the use of complex functions, formulas, and features to perform detailed data analysis and automate tasks. It includes tools like Power Query, Power Pivot, and VBA for building dynamic reports and automating processes.",
        "Advanced Excel helps users transform raw data into insightful reports, perform complex calculations, and create interactive dashboards and models.",
        "The main components of Advanced Excel include functions like VLOOKUP, INDEX-MATCH, PivotTables, data visualization tools (charts and graphs), and data analysis add-ins such as Solver and Analysis ToolPak.",
        "IT Accurate offers comprehensive Advanced Excel training, both offline and online, covering everything from basic functions to advanced techniques like automation using VBA and data modeling using Power Pivot.",
        "With Advanced Excel skills, professionals can enhance their career prospects in fields like finance, data analysis, project management, and more, making it a highly valuable skill for anyone in the workforce.",
        "IT Accurate is the best training institute for Advanced Excel in Mumbai, providing expert guidance, hands-on experience, and 100% job assistance for individuals seeking to elevate their Excel proficiency for career advancement.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //     { Image: dev1, alt: "Award 1" },
  //     { Image: dev2, alt: "Award 2" },
  //     { Image: dev3, alt: "Award 3" },
  //     { Image: dev4, alt: "Award 4" },
  //     { Image: dev5, alt: "Award 5" },
  //     { Image: dev6, alt: "Award 6" },
  //     { Image: dev7, alt: "Award 7" },
  //     { Image: dev8, alt: "Award 8" },
  // ];

  // const reviewList = [
  //     { Image: rev1, alt: "rev1" },
  //     { Image: rev2, alt: "rev1" },
  //     { Image: rev3, alt: "rev1" },
  //     { Image: rev4, alt: "rev1" },
  //     { Image: rev5, alt: "rev1" },
  // ]
  const keyFeatures = [
    { text: "Limited Students Batch", icon: "fa-users" },
    { text: "Personalised Attention", icon: "fa-user-check" },
    { text: "Highly Qualified Teachers", icon: "fa-chalkboard-teacher" },
    { text: "Flexible Batch Timings", icon: "fa-calendar-alt" },
    { text: "Interactive Learning", icon: "fa-comments" },
    { text: "Live Projects", icon: "fa-laptop-code" },
    { text: "Career Support", icon: "fa-briefcase" },
    { text: "Job Oriented Training", icon: "fa-graduation-cap" },
  ];
  return (
    <div className="devops-page">
      {/* <SideDropdown roadmapData={modulesData} /> */}
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best Advanced Excel Training</h1>
          <p>
            IT Accurate offers the best Advanced Excel training in Mumbai for
            both freshers and experienced professionals. With expert guidance
            and a 100% job placement guarantee, our Advanced Excel course covers
            essential topics such as complex formulas, data analysis tools,
            automation with VBA, and more.
          </p>
          <br />
          <p>
            Our practical, job-oriented training program not only provides a
            certification but also equips you with skills equivalent to 2+ years
            of industry experience. Designed to be completed in just 3 months,
            our training focuses on practical, project-based learning to ensure
            you can apply what you’ve learned in real-world scenarios.
          </p>

          <ul className="points-list">
            <li>Advanced Excel Functions and Formulas - 1 month</li>
            <li>Data Analysis and Visualization in Excel - 1 month</li>
            <li>Automation with VBA and Power Pivot - 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation and
              portfolio building
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview
              preparation and mock interviews
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-month internship with
              live projects
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job placement
              assistance
            </li>
          </ul>
        </div>

        <div className="form-contact">
          <ContactForm title="Get in Touch" submitText="Submit" />
        </div>
      </div>

      <WhatsDevOps whatsData={whatsData} />

      <Roadmap
        heading="Roadmap to Learn Advanced Excel"
        roadmapData={roadmapData}
      />
      <Syllabus
        heading=" Advanced Excel  Training Syllabus"
        Syllabus={syllabus}
      />
      <KeyFeatures
        heading=" Advanced Excel Training"
        keyFeatures={keyFeatures}
      />

      <section className="devops-certification-section">
        <h2 className="certification-heading"> Advanced Excel CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                IT Accurate training certification will serve as proof that you
                have completed the comprehensive Advanced Excel training
                program.
              </li>
              <li>
                The Advanced Excel certification provided by IT Accurate equips
                you with in-demand Excel skills such as complex formulas, data
                analysis, automation with VBA, and more, enhancing your job
                prospects in data analysis and business intelligence roles.
              </li>
              <li>
                IT Accurate offers detailed guidance for global certifications
                related to Excel, ensuring a 100% passing guarantee in exams
                such as the Microsoft Office Specialist (MOS) Excel
                certification and other recognized global Excel certifications.
              </li>
            </ul>
          </div>

          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      {/* <Roadmap heading="SAP modules" roadmapData={modulesData} /> */}

      {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
                <h2 className="slider-heading">SAP ABAP Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}

      <MarqueeLogos />
    </div>
  );
};

export default SAPABAP;
